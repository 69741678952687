import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTenant } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { useLoyaltyProgramTemplateApi } from "../../../hooks";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const LoyaltyProgramTemplateEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: tenantId, templateId } = useParams<{ id: string; templateId: string }>();

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });
  const {
    loyaltyProgramTemplate: currentLoyaltyTemplate,
    editLoyaltyProgramTemplate,
    applyToProductsApi,
  } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const handleRouteChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/edit-template/${id}/${getLastRoute(location.pathname)}`);
    },
    [location.pathname, navigate, tenantId],
  );

  return currentLoyaltyTemplate?.id && handleRouteChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute({ tenant, currentLoyaltyTemplate, editLoyaltyProgramTemplate, applyToProductsApi })}
      deps={[currentLoyaltyTemplate?.id, tenant?.loyaltyProgramTemplates, handleRouteChange]}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect
              value={currentLoyaltyTemplate?.id}
              entities={tenant?.loyaltyProgramTemplates ?? []}
              onChange={handleRouteChange}
            />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
    />
  ) : null;
};
