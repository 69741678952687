import { FC, useMemo } from "react";
import { Alert, Form, Select } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { FieldValue } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../../hooks";

import { ageOption, autoOption, manualOption } from "./constants";

export const ProductCancellationSettings: FC = () => {
  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();
  const [form] = Form.useForm<FormValues>();
  const { currentInsuranceProgram: currentInsurance, editInsurance, formValuesConverter } = useInsuranceProgramApi();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [ageOption.code, autoOption.code, manualOption.code],
    },
    allowParse: false,
  });

  const blockCodeValuesAndLabelsMap = (options: FieldValue[] | undefined) => {
    return options?.map(({ value, displayed }) => (
      <Select.Option key={value} value={value}>
        {displayed}
      </Select.Option>
    ));
  };

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: "Insurance Program Templates",
        href: `/tenant/${tenantId}/insurance-program-templates`,
      },
      ...(currentProduct.parameterTables?.flatMap(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/insurance-edit/${insuranceId}/insurance-premium-settings?tab=waiving`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - ${currentInsurance?.name} - Waiving and Cancellation`,
      })) || []),
    ],
    [currentInsurance, currentProduct, tenantId, productId, insuranceId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Cancellation Settings"
      pageSubtitle="Insurance can be cancelled for an account at any time; manually by the customer, or automatically by system, based upon certain factors like account delinquency level or customer age. You can also specify program re-instantiation mode from below parameters."
      size="md"
      formSize="full"
      gap={24}
      submitLabel="Save"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      level="product"
      linkTitle="Product Settings"
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={autoOption.code}
        label={autoOption.name}
        tooltip={{
          title: autoOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={autoOption.default}
        rules={[
          {
            validator: (_, value) =>
              value >= form.getFieldValue(manualOption.code) ? Promise.reject() : Promise.resolve(),
            message:
              "Delinquency level for auto re-instantiation can not be greater than or equal to selected manual re-instantiation level",
          },
        ]}
      >
        <Select>{blockCodeValuesAndLabelsMap(autoOption.options)}</Select>
      </Form.Item>

      <Form.Item
        name={manualOption.code}
        label={manualOption.name}
        tooltip={{
          title: manualOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={manualOption.default}
        rules={[
          {
            validator: (_, value) =>
              value <= form.getFieldValue(autoOption.code) ? Promise.reject() : Promise.resolve(),
            message:
              "Delinquency level for manual re-instantiation cannot be less than selected for automated re-instantiation",
          },
        ]}
      >
        <Select>{blockCodeValuesAndLabelsMap(manualOption.options)}</Select>
      </Form.Item>

      <Alert
        message="Below preferences will be applied to all contracts under this product, you can override below parameter(s) value for a specific pricing control table in related screens."
        type="info"
        showIcon={true}
      />

      <Form.Item
        name={ageOption.code}
        label={ageOption.name}
        tooltip={{
          title: ageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={ageOption.default}
      >
        <NetworkForm.Number
          addonAfter="years"
          min={ageOption.min}
          max={ageOption.max}
          precision={ageOption.decimalsNumber}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
