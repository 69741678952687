import { FormValues, RoutesTreeItem } from "@ni/common/types";
import { LoyaltyProgramTemplate, Tenant } from "@ni/sdk/models";

import {
  LoyaltyApplyingToProducts,
  PointAccrualSetup,
  PointExpiry,
  PointRedemptionSetup,
  TransactionEligibility,
} from "../../../pages/tenant";

interface Props {
  tenant: Tenant | undefined;
  currentLoyaltyTemplate: LoyaltyProgramTemplate;
  editLoyaltyProgramTemplate: (
    data: FormValues,
    listKey?: "programValues" | "programPctValues" | "both",
  ) => Promise<void>;
  applyToProductsApi: (ids: number[]) => Promise<void>;
}

export const pagesRoute = ({ tenant, currentLoyaltyTemplate, editLoyaltyProgramTemplate, applyToProductsApi }: Props) =>
  [
    {
      name: "Transaction Eligibility",
      route: "transaction-eligibility",
      element: (
        <TransactionEligibility
          loyaltyProgramTemplate={currentLoyaltyTemplate}
          editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
        />
      ),
    },
    {
      name: "Point Accrual Setup",
      route: "point-accrual-setup",
      element: (
        <PointAccrualSetup
          tenant={tenant ?? {}}
          loyaltyProgramTemplate={currentLoyaltyTemplate}
          editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
        />
      ),
    },
    {
      name: "Point Redemption Setup",
      route: "redemption-setup",
      element: (
        <PointRedemptionSetup
          tenantId={tenant?.id ?? 0}
          loyaltyProgramTemplate={currentLoyaltyTemplate}
          editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
        />
      ),
    },
    {
      name: "Point Expiry",
      route: "point-expiry",
      element: (
        <PointExpiry
          loyaltyProgramTemplate={currentLoyaltyTemplate}
          editLoyaltyProgramTemplate={editLoyaltyProgramTemplate}
        />
      ),
    },
    {
      name: "Applying to Products",
      route: "applying-to-products",
      element: (
        <LoyaltyApplyingToProducts
          tenantId={tenant?.id ?? 0}
          applyToProductsApi={applyToProductsApi}
          loyaltyProgramTemplate={currentLoyaltyTemplate}
        />
      ),
    },
  ] as RoutesTreeItem[];
