export const deliveryChannel: { label: string; value: string }[] = [
  {
    label: "Tenant SMS Gateway",
    value: "TIBCO",
  },
  {
    label: "Network International SMS Gateway",
    value: "M",
  },
];
