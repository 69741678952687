import { FC } from "react";
import { Form, Input, Select } from "antd";

import { useGetCurrencyList, useHydrateForm, useTenant } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

import { useInsuranceApi } from "../../../../hooks";

import { companyNameOption, currencyOption, templateNameOption } from "./constants";

export const Identification: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { tenant, setTenant } = useTenant();
  const { currentInsurance, editInsurance } = useInsuranceApi();
  const { currencyList } = useGetCurrencyList();

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values
      ? [
          ...(currentInsurance?.values ?? []),
          ...[
            { fieldCode: templateNameOption.code, value: currentInsurance?.name },
            { fieldCode: companyNameOption.code, value: currentInsurance?.insuranceCompanyName },
            { fieldCode: currencyOption.code, value: currentInsurance?.currency?.toString() },
          ],
        ]
      : [],
    keys: {
      strings: [templateNameOption.code, companyNameOption.code, currencyOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({
      name: values[templateNameOption.code] as string,
      insuranceCompanyName: values[companyNameOption.code] as string,
      currency: values[currencyOption.code] as string,
    }).then(() => {
      const insuranceIndex = tenant?.insuranceProgramTemplates?.findIndex(x => x.id === currentInsurance.id);
      if (
        Number.isInteger(insuranceIndex) &&
        values[templateNameOption.code] &&
        tenant?.insuranceProgramTemplates![insuranceIndex as number] !== values[templateNameOption.code]
      ) {
        setTenant((current: Tenant | undefined) => {
          if (current?.insuranceProgramTemplates![insuranceIndex as number]?.name)
            current.insuranceProgramTemplates[insuranceIndex as number].name = values[
              templateNameOption.code
            ] as string;
          return current;
        });
      }
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Template Identification"
      pageSubtitle="You can update below template identification information; this will be applied on new programs created going forward."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
    >
      <Form.Item
        name={templateNameOption.code}
        label={templateNameOption.name}
        rules={[{ required: true, message: "Insurance program name is required" }]}
        initialValue={templateNameOption.default}
      >
        <Input maxLength={64} />
      </Form.Item>

      <Form.Item name={companyNameOption.code} label={companyNameOption.name}>
        <Input maxLength={24} />
      </Form.Item>

      <Form.Item name={currencyOption.code} label={currencyOption.name}>
        <Select showSearch={true} filterOption={filterByDisplayValue}>
          {currencyList?.map(currency => (
            <Select.Option key={currency.value} value={currency.value}>
              {currency.displayValue}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </CustomFormWrapper>
  );
};
