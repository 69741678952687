import { FC } from "react";
import { Form, FormInstance, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  LTY_WELC_BONUS_TARGET,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
} from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { FormContent, NetworkForm, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { LoyaltyProgram } from "@ni/sdk/models";

import { RADIO_GROUP_TIMER_FROM, RADIO_GROUP_TIMER_PER_TYPE } from "./WelcomeBonus.constants";

interface WelcomeBonusProps {
  form: FormInstance<FormValues>;
  loyaltyPrograms: LoyaltyProgram[];
}

export const WelcomeBonus: FC<WelcomeBonusProps> = ({ form, loyaltyPrograms }) => {
  const chosenLoyaltyProgram = Form.useWatch(LTY_WELC_BONUS_TARGET, form);

  return (
    <FormContent gap={40}>
      <TooltipInfo
        largeLabel="New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a
        fixed period of time."
        tooltipProps={{
          title:
            "Bonus amount can be redefined for a particular Pricing control table. Please follow the link at the bottom of the page.",
        }}
      />

      <Form.Item
        name={LTY_WELC_BONUS_TARGET}
        label="Choose the loyalty program you want to apply welcome bonus"
        tooltip={{
          title: "Bonus is added to the point bucket of the appropriate program",
          icon: <QuestionCircleFilled />,
        }}
      >
        <Select optionFilterProp="children">
          {[{ id: 0, loyaltyCode: "None" }, ...loyaltyPrograms].map(loyaltyProgram => (
            <Select.Option
              key={loyaltyProgram.id}
              value={
                loyaltyProgram.loyaltyCode !== "None"
                  ? loyaltyProgram.loyaltyCode?.toUpperCase()
                  : loyaltyProgram.loyaltyCode
              }
            >
              {loyaltyProgram.loyaltyCode !== "None" ? loyaltyProgram.loyaltyCode?.toUpperCase() : "None"}{" "}
              {loyaltyProgram.name && loyaltyProgram.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {chosenLoyaltyProgram && chosenLoyaltyProgram !== "None" && (
        <>
          <Form.Item name={NIC_LTY_BONUS_W_TIMER_FROM} label="Welcome bonus timer trigger (count down starts from):">
            <RadioGroup radioList={RADIO_GROUP_TIMER_FROM} />
          </Form.Item>

          <Form.Item name={NIC_LTY_BONUS_W_TIMER_PER_TYPE} label="Welcome bonus count down timer period">
            <RadioGroup radioList={RADIO_GROUP_TIMER_PER_TYPE} />
          </Form.Item>

          <Form.Item name={NIC_LTY_BONUS_W_TIMER_PER} label="Welcome bonus count down timer (number of periods)">
            <NetworkForm.Number min={0} precision={0} />
          </Form.Item>
        </>
      )}
    </FormContent>
  );
};
