import { ReactNode } from "react";
import { Form, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { NetworkForm, TooltipInfo } from "@ni/common/ui";

export type KeyTab = "setting" | "coverage" | "waiving";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

// tab 1
const fixedOption: NumericOptions = {
  code: "ins-prem-fixed",
  name: "Insurance fixed premium amount",
  decimalsNumber: 2,
  min: 0,
};
const percentageOption: NumericOptions = {
  code: "ins-prem-rate",
  name: "Insurance premium percentage",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  default: 0.89,
  tooltip: (
    <Space direction="vertical">
      <div>
        This percentage will be charged on customer outstanding liability in addition to the insurance fixed premium
        amount.
      </div>
    </Space>
  ),
};
const capOption: NumericOptions = {
  code: "ins-prem-min",
  name: "Minimum insurance premium cap",
  decimalsNumber: 2,
  min: 0,
  tooltip: (
    <Space direction="vertical">
      <div>
        This specifies the minimum amount that will be charged to customer, if the Total Insurance Premium (output of
        the Insurance fixed amount + Insurance premium percentage formula amount) is less
      </div>
    </Space>
  ),
};
export const tab1 = {
  fixedOption,
  percentageOption,
  capOption,
  page: ({ currency }: { currency: string }) => (
    <Space direction="vertical" size="large">
      <Form.Item name={fixedOption.code} label={fixedOption.name}>
        <NetworkForm.Number
          addonAfter={currency}
          min={fixedOption.min}
          max={fixedOption.max}
          precision={fixedOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={percentageOption.code}
        label={percentageOption.name}
        tooltip={{
          title: percentageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={percentageOption.default}
      >
        <NetworkForm.Number
          addonAfter="%"
          min={percentageOption.min}
          max={percentageOption.max}
          precision={percentageOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={capOption.code}
        label={capOption.name}
        tooltip={{
          title: capOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          addonAfter={currency}
          min={capOption.min}
          max={capOption.max}
          precision={capOption.decimalsNumber}
        />
      </Form.Item>
    </Space>
  ),
} as const;

// tab 2
const maximumCoverageOption: NumericOptions = {
  code: "ins-cov-max",
  name: "Insurance maximum coverage amount",
  decimalsNumber: 2,
  min: 0,
  tooltip: "Specify the maximum insurance coverage amount for credit card holder outstanding balance",
};
export const tab2 = {
  maximumCoverageOption,
  page: ({ currency }: { currency: string }) => (
    <Space direction="vertical" size="large">
      <Form.Item
        name={maximumCoverageOption.code}
        label={maximumCoverageOption.name}
        tooltip={{
          title: maximumCoverageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          addonAfter={currency}
          min={maximumCoverageOption.min}
          max={maximumCoverageOption.max}
          precision={maximumCoverageOption.decimalsNumber}
        />
      </Form.Item>
    </Space>
  ),
} as const;

// tab 3
const WaivePremiumOption: Options = {
  code: "ins-prem-waive",
  name: "Waive premium for transactor customer",
  tooltip: "Premium will be waived if customer has paid the previous cycle statement balance",
};
const cancellationAgeLimitOption: NumericOptions = {
  code: "ins-cncl-age-limit",
  name: "Insurance program cancellation age limit",
  decimalsNumber: 0,
  min: 0,
  tooltip: "In case customer reaches age defined in this parameter then premium will be automatically cancelled",
};
export const tab3 = {
  cancellationAgeLimitOption,
  WaivePremiumOption,
  page: () => (
    <Space direction="vertical" size="large">
      <Space direction="horizontal">
        <Form.Item name={WaivePremiumOption.code} valuePropName="checked" initialValue={WaivePremiumOption.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={WaivePremiumOption.name} tooltipProps={{ title: WaivePremiumOption.tooltip }} />
      </Space>

      <Form.Item
        name={cancellationAgeLimitOption.code}
        label={cancellationAgeLimitOption.name}
        tooltip={{
          title: cancellationAgeLimitOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          addonAfter="years"
          min={cancellationAgeLimitOption.min}
          max={cancellationAgeLimitOption.max}
          precision={cancellationAgeLimitOption.decimalsNumber}
        />
      </Form.Item>
    </Space>
  ),
} as const;
