import { FC, useMemo } from "react";
import { Button, Collapse, Space, Switch, Table, Tooltip } from "antd";
import cn from "classnames";

import { BugOutlined, RightOutlined, SettingOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormItemLabel } from "@ni/common/ui";
import { displayReadableSmsVariables } from "@ni/common/utils";
import { Event, EventGroup, Sms, Variable } from "@ni/sdk/models";

import { EditableCell } from "../EditableCell";

import styles from "./styles.module.scss";

interface EventGroupListProps {
  items?: EventGroup[];
  onEditClick: (event: Event, _groupId: number, smsDetails: Sms) => void;
  smsList?: Sms[];
  onSwitchClick: (event: Event, smsDetails: Sms) => void;
  variablesList: (Variable | undefined)[];
  search: string;
}

const { Panel } = Collapse;

export const EventGroupList: FC<EventGroupListProps> = ({
  items = [],
  onEditClick,
  smsList = [],
  onSwitchClick,
  variablesList,
  search,
}) => {
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);
  const extraHeader = (name: string | undefined) => <div className={styles["title"]}>{name ?? ""}</div>;

  const smsDictionary = useMemo(() => {
    const dictionary: Record<string, Sms> = {};
    smsList.forEach(smsEvent => {
      if (smsEvent?.smsEvent?.code) dictionary[smsEvent?.smsEvent?.code] = { ...smsEvent };
    });
    return dictionary;
  }, [smsList]);

  const testSwitch = (event: Event, smsDetails: Sms) => {
    onSwitchClick(event, smsDetails);
  };

  const testEdit = (event: Event, _groupId: number, smsDetails: Sms) => {
    onEditClick(event, _groupId, smsDetails);
  };

  return (
    <div>
      <Collapse
        bordered={true}
        className={cn(styles["collapsible-list"], styles["collapsible-list--bolded"])}
        expandIcon={({ isActive }) => <RightOutlined style={{ color: "#080808" }} rotate={isActive ? 90 : 0} />}
        activeKey={isPrintMode ? items.map(item => item.id) : undefined}
      >
        {items?.map(item => {
          return (
            <Panel header={extraHeader(item.name)} key={item.id}>
              <Table
                rowKey="id"
                columns={[
                  {
                    title: "General template for group",
                    key: "id",
                    width: "29%",
                    render: (_, rowData) => {
                      const generalTemplateEvent = rowData.events?.find(x => x.isGeneral);
                      const smsEvent =
                        (generalTemplateEvent?.code && smsDictionary[generalTemplateEvent?.code]) ??
                        ({
                          isActive: false,
                        } as Sms);

                      if (smsEvent)
                        return (
                          <Space>
                            <Switch
                              value={smsEvent.isActive}
                              onChange={isActive => {
                                if (generalTemplateEvent) testSwitch(generalTemplateEvent, { ...smsEvent, isActive });
                              }}
                            />
                            <FormItemLabel label={rowData?.name} code={`${generalTemplateEvent?.code}`} />
                          </Space>
                        );
                      return <BugOutlined />;
                    },
                  },
                  {
                    title: "SMS Template",
                    dataIndex: "id",
                    key: "id",
                    width: "34%",
                    render: (_, rowData) => {
                      const generalTemplateEvent = rowData.events?.find(x => x.isGeneral);
                      const smsEvent =
                        (generalTemplateEvent?.code && smsDictionary[generalTemplateEvent?.code]) ??
                        ({
                          isActive: false,
                        } as Sms);
                      if (smsEvent)
                        return (
                          <EditableCell
                            key={rowData?.id}
                            text={smsEvent?.fieldValue}
                            onSubmit={fieldValue => {
                              if (generalTemplateEvent) testSwitch(generalTemplateEvent, { ...smsEvent, fieldValue });
                            }}
                          />
                        );
                      return <BugOutlined />;
                    },
                  },
                  {
                    title: "Displayed SMS",
                    dataIndex: "id",
                    key: "id",
                    render: (_, rowData) => {
                      const generalTemplateEvent = rowData.events?.find(x => x.isGeneral);
                      const smsEvent =
                        (generalTemplateEvent?.code && smsDictionary[generalTemplateEvent?.code]) ??
                        ({
                          isActive: false,
                        } as Sms);

                      if (smsEvent)
                        return displayReadableSmsVariables(
                          smsEvent?.fieldValue ? smsEvent?.fieldValue : "N/A",
                          variablesList as Variable[],
                        );
                      return <BugOutlined />;
                    },
                  },
                  {
                    title: "",
                    dataIndex: "id",
                    key: "id",
                    width: "3%",
                    render: (_, rowData) => {
                      const generalTemplateEvent = rowData.events?.find(x => x.isGeneral);
                      const smsEvent =
                        (generalTemplateEvent?.code && smsDictionary[generalTemplateEvent?.code]) ??
                        ({
                          isActive: false,
                        } as Sms);
                      return (
                        <Tooltip title="Edit">
                          <Button
                            type="text"
                            htmlType="button"
                            icon={<SettingOutlined />}
                            onClick={() => {
                              if (generalTemplateEvent && smsEvent)
                                testEdit(generalTemplateEvent, generalTemplateEvent?.eventSubgroupId ?? 0, smsEvent);
                            }}
                            className="text-primary"
                          />
                        </Tooltip>
                      );
                    },
                  },
                ]}
                expandable={{
                  expandedRowRender: eventSubGroup => {
                    const innerTableData = eventSubGroup
                      ? eventSubGroup.events?.filter(
                          y =>
                            !y.isGeneral &&
                            `${y?.eventName?.toLowerCase() ?? ""} ${y.code?.toLowerCase() ?? ""}`.includes(
                              search ?? "",
                            ),
                        )
                      : [];
                    return innerTableData?.length ? (
                      <Table
                        key={eventSubGroup?.id?.toString()}
                        rowKey="id"
                        columns={[
                          {
                            title: "Sub-Event Name",
                            dataIndex: "id",
                            key: "id",
                            render: (_, rowData) => {
                              const smsEvent =
                                (rowData?.code && smsDictionary[rowData?.code]) ??
                                ({
                                  isActive: false,
                                } as Sms);

                              if (smsEvent)
                                return (
                                  <Space>
                                    <Switch
                                      value={smsEvent.isActive}
                                      onChange={isActive => {
                                        testSwitch(rowData, {
                                          ...smsEvent,
                                          isActive,
                                        });
                                      }}
                                    />

                                    <FormItemLabel label={rowData?.eventName} code={`(NIC-sms-mt).${rowData?.code}`} />
                                  </Space>
                                );
                              return <BugOutlined />;
                            },
                            width: "30%",
                          },
                          {
                            title: "SMS Template",
                            dataIndex: "id",
                            key: "id",
                            width: "34%",
                            render: (_, rowData) => {
                              const smsEvent =
                                (rowData?.code && smsDictionary[rowData?.code]) ??
                                ({
                                  isActive: false,
                                } as Sms);
                              if (smsEvent)
                                return (
                                  <EditableCell
                                    text={smsEvent?.fieldValue}
                                    onSubmit={fieldValue => {
                                      if (rowData) testSwitch(rowData, { ...smsEvent, fieldValue });
                                    }}
                                  />
                                );

                              return <BugOutlined />;
                            },
                          },
                          {
                            title: "Displayed SMS",
                            dataIndex: "id",
                            key: "id",
                            render: (_, rowData) => {
                              const smsEvent =
                                (rowData?.code && smsDictionary[rowData?.code]) ??
                                ({
                                  isActive: false,
                                } as Sms);
                              if (smsEvent)
                                return displayReadableSmsVariables(
                                  smsEvent?.fieldValue ?? "N/A",
                                  variablesList as Variable[],
                                );
                              return <BugOutlined />;
                            },
                          },
                          {
                            title: "",
                            dataIndex: "id",
                            key: "id",
                            width: "3%",
                            render: (_, rowData) => {
                              const smsEvent = ((rowData?.code && smsDictionary[rowData?.code]) ?? {
                                isActive: false,
                              }) as Sms;
                              return (
                                <Tooltip title="Edit">
                                  <Button
                                    type="text"
                                    htmlType="button"
                                    icon={<SettingOutlined />}
                                    onClick={() => {
                                      testEdit(rowData, rowData?.eventSubgroupId ?? 0, smsEvent);
                                    }}
                                    className="text-primary"
                                  />
                                </Tooltip>
                              );
                            },
                          },
                        ]}
                        dataSource={innerTableData}
                        size="small"
                        pagination={false}
                      />
                    ) : null;
                  },
                  defaultExpandAllRows: isPrintMode,
                }}
                dataSource={item.eventSubgroups?.filter(
                  x =>
                    x?.name?.toLowerCase().includes(search) ||
                    (x?.events?.filter(
                      y =>
                        !y.isGeneral &&
                        `${y?.eventName?.toLowerCase() ?? ""} ${y.code?.toLowerCase() ?? ""}`.includes(search ?? ""),
                    )?.length ?? 0) > 0 ||
                    x?.events?.find(x => x.isGeneral && x.code?.toLowerCase()?.includes(search)),
                )}
                size="small"
                pagination={false}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
