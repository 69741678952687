import { FormValues, RoutesTreeItem } from "@ni/common/types";
import { LoyaltyProgram, Product, Tenant } from "@ni/sdk/models";

import { LoyaltyPointAccrualSetup, LoyaltyPointExpiry, LoyaltyTransactionEligibility } from "../../../pages";

interface Props {
  tenant: Tenant | undefined;
  currentProduct: Product;
  loyaltyProgram: LoyaltyProgram;
  editLoyaltyProgram: (data: FormValues) => Promise<void>;
  productCurrency: string;
}

export const pagesRoute = ({ tenant, currentProduct, loyaltyProgram, editLoyaltyProgram, productCurrency }: Props) =>
  [
    {
      name: "Transaction Eligibility",
      route: "transaction-eligibility",
      element: (
        <LoyaltyTransactionEligibility
          loyaltyProgram={loyaltyProgram}
          productCurrency={productCurrency}
          editLoyaltyProgram={editLoyaltyProgram}
        />
      ),
    },
    {
      name: "Point Accrual Setup",
      route: "point-accrual-setup",
      element: (
        <LoyaltyPointAccrualSetup
          tenant={tenant ?? {}}
          currentProduct={currentProduct}
          loyaltyProgram={loyaltyProgram}
          editLoyaltyProgram={editLoyaltyProgram}
        />
      ),
    },
    {
      name: "Point Expiry",
      route: "point-expiry",
      element: <LoyaltyPointExpiry loyaltyProgram={loyaltyProgram} editLoyaltyProgram={editLoyaltyProgram} />,
    },
  ] as RoutesTreeItem[];
