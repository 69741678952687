import { FC, useMemo } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE, LTY_PROGRAM_ACCR_TYPE } from "@ni/common/constants";
import { useHydrateForm, useProductLoyaltyPrograms } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup } from "@ni/common/ui";
import { LoyaltyProgram, LoyaltyProgramTemplateValue, Product } from "@ni/sdk/models";

import { RADIO_GROUP_ACCRUAL_FREQ, RADIO_GROUP_ACCRUAL_MODE, RADIO_GROUP_ACCRUAL_TYPE } from "../constants";

interface EditProgramProps {
  currentProduct: Product;
  loyaltyProgram: LoyaltyProgram;
  editProductLoyaltyProgram: (values: FormValues) => Promise<void>;
}

const stringsKeys = [LTY_PROGRAM_ACCR_TYPE, LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE];

export const PointAccrualSetup: FC<EditProgramProps> = ({
  currentProduct,
  loyaltyProgram,
  editProductLoyaltyProgram,
}) => {
  const [form] = Form.useForm<FormValues>();

  const { fetchLoyaltyPrograms } = useProductLoyaltyPrograms({
    productId: currentProduct?.id ?? 0,
    isFetchEnabled: false,
  });

  useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgram?.programValues ?? []),
        { code: [LTY_PROGRAM_ACCR_TYPE], value: loyaltyProgram?.type },
      ] as LoyaltyProgramTemplateValue[],
      keys: {
        strings: stringsKeys,
      },
    },
    [loyaltyProgram],
  );

  const programTypeCumulative = Form.useWatch<string>(LTY_PROGRAM_ACCR_TYPE, form) === "CUMULATIVE";

  const onFinish = (values: FormValues) => {
    void editProductLoyaltyProgram(values).then(() => void fetchLoyaltyPrograms(currentProduct?.id ?? 0));
  };

  const relatedLinks = useMemo(() => {
    return (currentProduct.parameterTables ?? []).map(table => ({
      href: `../pct/${table?.id}/loyalty-program/${loyaltyProgram?.id}/point-accrual-setup`,
      label: `${currentProduct?.displayName} - ${table?.displayName} PCT - Loyalty Programs - "${loyaltyProgram?.name}" - Point Accrual Setup`,
    }));
  }, [currentProduct.displayName, currentProduct.parameterTables, loyaltyProgram?.id, loyaltyProgram?.name]);

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Accrual Setup"
      pageSubtitle="Loyalty points are earned for eligible transactions. The below parameters define the basic accrual setup for the loyalty program. Accrual rate can be redefined for a particular Pricing control table. Please follow the link at the bottom of the page."
      size="md"
      submitLabel="Save"
      submitHandler={onFinish}
      level="product-loyalty"
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={LTY_PROGRAM_ACCR_TYPE}
        label="Choose loyalty program accrual type:"
        tooltip={{
          title:
            "Changing the accrual type is not permitted. If this is necessary, kindly create a new loyalty program from the template with the desired type.",
          icon: <QuestionCircleFilled />,
        }}
      >
        <RadioGroup radioList={RADIO_GROUP_ACCRUAL_TYPE} disabled={true} />
      </Form.Item>

      {programTypeCumulative && (
        <>
          <Form.Item name={LTY_PROGRAM_ACCR_FREQ} label="Choose point accrual frequency:" initialValue="B">
            <RadioGroup radioList={RADIO_GROUP_ACCRUAL_FREQ} />
          </Form.Item>

          <Form.Item name={LTY_PROGRAM_ACCR_MODE} label="Choose cumulative program accrual mode:" initialValue="AMOUNT">
            <RadioGroup radioList={RADIO_GROUP_ACCRUAL_MODE} />
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
