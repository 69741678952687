import { FC, useEffect } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGroup } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { ClosureTriggering } from "@ni/tenant-portal/core";

import styles from "./styles.module.scss";

export const ManualClosure: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  useEffect(() => {
    if (!product?.id) {
      return;
    }

    form.setFieldsValue({
      "nic-acc-st-man-close-days":
        (getFormValueFromProductValues(product.productValues, "nic-acc-st-man-close-days") as string) || "",
      "nic-card-st-man-close-days":
        (getFormValueFromProductValues(product.productValues, "nic-card-st-man-close-days") as string) || "",
    });
  });

  const onSubmit = (values: FormValues) => {
    void onUpdateProduct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Manual Contract Closure"
      pageSubtitle={<ClosureTriggering showLinks={true} />}
      submitHandler={onSubmit}
      size="full"
      formSize="full"
      submitLabel="Save"
      gap={24}
    >
      <PageItemLayoutGroup>
        <PageItemLayoutGroup className={styles["product-details-form"]}>
          <div className="text-blue-h3">Manual closure (Cancellation) settings</div>
          <Form.Item
            name="nic-acc-st-man-close-days"
            label="Pre-closure period for account (number of days)"
            className={styles["product-details-form-input"]}
            rules={[
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(value, "Number of days", 0, 365);
                },
              }),
            ]}
          >
            <NetworkForm.Number precision={0} />
          </Form.Item>
          <Form.Item
            name="nic-card-st-man-close-days"
            label="Pre-closure period for cards (number of days)"
            className={styles["product-details-form-input"]}
            tooltip={{
              title:
                "Cancelling the card is needed mostly for the supplementary card or for the cases when more than one primary card is allowed. It is used for canceling one specific card without cancelling the account.",
              icon: <QuestionCircleFilled />,
            }}
            rules={[
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(value, "Number of days", 0, 365);
                },
              }),
            ]}
          >
            <NetworkForm.Number precision={0} />
          </Form.Item>
        </PageItemLayoutGroup>
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
