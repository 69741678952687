import { FC } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_ACCR_Y_RATE } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const PointAccrualCalculationYerlyCumulativeCycle: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);
  const { processWizardRequest } = useLoyaltyWizardApi();

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      strings: [LTY_ACCR_Y_RATE],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: values as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={false}
      pageTitle="Point Calculation -  Yearly Accrual"
      pageSubtitle="Points are earned as a percentage of eligible transactions occurred during rolling year."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item
        name={LTY_ACCR_Y_RATE}
        required={true}
        rules={[{ required: true }]}
        label="Points accrual percentage rate"
        tooltip={{
          title:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the year",
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number min={0} precision={2} addonAfter="%" stringMode={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
