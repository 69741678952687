import { DictionarySelect } from "../DictionarySelect";

import { CheckBox } from "./CheckBox";
import { Number } from "./Number";
import { Radio } from "./Radio";
import { Select } from "./Select";
import { String } from "./String";
import { Switch } from "./Switch";

export const NetworkForm = { CheckBox, Number, String, Select, Switch, Radio, DictionarySelect };
