import { FC } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { minMaxValidator } from "@ni/common/utils";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import {
  NIC_IPP_INST_SCHEME_PLAN_MAX,
  NIC_IPP_INST_SCHEME_PLAN_MAX_DAYS,
  NIC_IPP_INST_SCHEME_PLAN_MIN,
} from "../../constants/forms.constants";
import { useIPPWizardApi } from "../../hooks";

export const IPPTransactionEligibilityPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useIPPWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useHydrateForm(
    {
      form,
      entityFields: wizardResponse.plan?.planValues ?? [],
      keys: {
        strings: [NIC_IPP_INST_SCHEME_PLAN_MIN, NIC_IPP_INST_SCHEME_PLAN_MAX, NIC_IPP_INST_SCHEME_PLAN_MAX_DAYS],
      },
      allowParse: false,
    },
    [wizardResponse],
  );

  const onFinish = async (values: FormValues) => {
    const min = values[NIC_IPP_INST_SCHEME_PLAN_MIN];
    const max = values[NIC_IPP_INST_SCHEME_PLAN_MAX];

    if (min !== null && max !== null && min > max) {
      form.setFields([
        {
          name: NIC_IPP_INST_SCHEME_PLAN_MIN,
          errors: ["Minimum cannot be higher than Maximum"],
        },
        {
          name: NIC_IPP_INST_SCHEME_PLAN_MAX,
          errors: ["Maximum cannot be lower than Minimum"],
        },
      ]);
      return;
    }

    setIsLoading(true);

    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Transaction Eligibility"
      pageSubtitle="The below parameters specify the requirements for retail transactions or balance amounts that need to be converted to IPP. In case of non-compliance, the amount will not be converted to IPP."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <Form.Item
        name={NIC_IPP_INST_SCHEME_PLAN_MIN}
        label="Minimum amount"
        tooltip={{
          title:
            "In case of transaction amount or balance amount is less than the defined amount, then transaction amount / balance amount will not be converted to IPP.",
          icon: <QuestionCircleFilled />,
        }}
        rules={[
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 0, null);
            },
          }),
        ]}
      >
        <NetworkForm.Number
          precision={2}
          controls={false}
          addonAfter={wizardResponse?.plan?.currency as string}
          className="w-p-100"
        />
      </Form.Item>

      <Form.Item
        name={NIC_IPP_INST_SCHEME_PLAN_MAX}
        label="Maximum amount"
        tooltip={{
          title:
            "In case of transaction amount or balance amount is greater than the defined amount, then transaction amount / balance amount will not be converted to IPP.",
          icon: <QuestionCircleFilled />,
        }}
        rules={[
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 0, null);
            },
          }),
        ]}
      >
        <NetworkForm.Number
          precision={2}
          controls={false}
          addonAfter={wizardResponse?.plan?.currency as string}
          className="w-p-100"
        />
      </Form.Item>

      <Form.Item
        name={NIC_IPP_INST_SCHEME_PLAN_MAX_DAYS}
        label="Maximum days from transaction"
        tooltip={{
          title:
            "In case transaction is tried to convert to IPP after the number of days defined in this parameter from the date of transaction, then IPP will not be booked.",
          icon: <QuestionCircleFilled />,
        }}
        rules={[
          () => ({
            validator(_, value: number) {
              return minMaxValidator(value, 0, null);
            },
          }),
        ]}
      >
        <NetworkForm.Number precision={0} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
