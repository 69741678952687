import { forwardRef, ReactNode } from "react";
import { Form, Space, Switch as AntdSwitch, SwitchProps, TooltipProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { TooltipInfo } from "../../TooltipInfo";

export type NetworkSwitchProps = { formItemOptions?: NetWorkFormItemProps; customLabel?: ReactNode } & SwitchProps;

export const Switch = forwardRef<HTMLButtonElement, NetworkSwitchProps>(
  ({ formItemOptions, customLabel, ...props }, ref) => {
    if (formItemOptions?.name) {
      return (
        <Space direction="horizontal">
          <Form.Item {...formItemOptions} label={undefined} tooltip={undefined}>
            <AntdSwitch ref={ref} {...props} />
          </Form.Item>

          {customLabel || (
            <TooltipInfo
              label={formItemOptions?.label}
              code={formItemOptions?.name as string}
              tooltipProps={
                (formItemOptions?.tooltip
                  ? typeof formItemOptions.tooltip === "string"
                    ? { title: formItemOptions?.tooltip }
                    : typeof formItemOptions.tooltip === "object" &&
                        (formItemOptions.tooltip as { title: ReactNode })?.title
                      ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                      : formItemOptions.tooltip
                  : {}) as TooltipProps
              }
            />
          )}
        </Space>
      );
    }
    return (
      <Space direction="horizontal">
        <AntdSwitch ref={ref} {...props} />
        <TooltipInfo
          label={formItemOptions?.label}
          tooltipProps={
            (formItemOptions?.tooltip
              ? typeof formItemOptions.tooltip === "string"
                ? { title: formItemOptions?.tooltip }
                : typeof formItemOptions.tooltip === "object" &&
                    (formItemOptions.tooltip as { title: ReactNode })?.title
                  ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                  : formItemOptions.tooltip
              : {}) as TooltipProps
          }
        />
      </Space>
    );
  },
);
