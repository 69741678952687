import { FC, useMemo } from "react";
import { Form, Space, Switch, Typography } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { murabahaScenariosColumns, murabahaScenariosData } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, NetworkForm, FormItemLabel, RadioGroup } from "@ni/common/ui";

import {
  murabahaFeeRate,
  murabahaInterestRate,
  murabahaProfitRate,
  transactionChargeProfit,
  transactionMaximumDays,
} from "./constants";

export const MurabahaProfitPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const isPostingCharge = Form.useWatch<string>(transactionChargeProfit.code, form)?.toString() === "P";

  const relatedLinks = useMemo(
    () => [
      ...(currentProduct.parameterTables?.map(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/murabaha-profit-calculation`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - Murabaha Profit Calculation`,
      })) || []),
    ],
    [currentProduct.displayName, currentProduct.parameterTables, productId, tenantId],
  );

  useHydrateForm({
    form,
    entityFields: currentProduct.productValues ?? [],
    keys: {
      strings: [
        murabahaFeeRate.code,
        murabahaInterestRate.code,
        murabahaProfitRate.code,
        transactionChargeProfit.code,
        transactionMaximumDays.code,
      ],
    },
    allowParse: false,
  });

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Murabaha Profit Settings"
      pageSubtitle="Murabaha, also referred to as cost-plus financing, is an Islamic financing structure in which the seller provides the cost and profit margin of an asset. Murabaha is not an interest-bearing loan (qardh ribawi) but is an acceptable form of credit sale under Islamic law. For Islamic covered Murabaha cards, two transactions are posted to the cardholder on the statement day, If a complete and timely payment is made, all profit on the retail balance will be forgiven."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={murabahaProfitRate.code}
        label={murabahaProfitRate.label}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: murabahaProfitRate.tooltip,
        }}
        rules={[{ required: true, message: `${murabahaProfitRate.label} is required!` }]}
      >
        <NetworkForm.Number {...murabahaProfitRate.props} />
      </Form.Item>

      <Form.Item
        name={transactionChargeProfit.code}
        label={<FormItemLabel label={transactionChargeProfit.label} code={transactionChargeProfit.code} />}
        initialValue={transactionChargeProfit.default}
      >
        <RadioGroup
          radioList={transactionChargeProfit.options}
          initialValue={transactionChargeProfit.default as string}
        />
      </Form.Item>

      {!isPostingCharge && (
        <Form.Item
          name={transactionMaximumDays.code}
          label={transactionMaximumDays.label}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: transactionMaximumDays.tooltip,
          }}
          rules={[{ required: true, message: `${transactionMaximumDays.label} is required!` }]}
        >
          <NetworkForm.Number {...transactionMaximumDays.props} />
        </Form.Item>
      )}

      <Space direction="vertical" size={16}>
        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={murabahaFeeRate.code}>
            <Switch />
          </Form.Item>
          <Typography.Text>{murabahaFeeRate.label}</Typography.Text>
        </Space>

        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={murabahaInterestRate.code}>
            <Switch />
          </Form.Item>
          <Typography.Text>{murabahaInterestRate.label}</Typography.Text>
        </Space>
      </Space>
      <CollapsedTable title="What if scenarios" tabsList={murabahaScenariosData} columns={murabahaScenariosColumns} />
    </CustomFormWrapper>
  );
};
