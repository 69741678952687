import { FC, useEffect, useState } from "react";
import { Avatar, Badge, Button } from "antd";
import { Link } from "react-router-dom";

import { logo } from "@ni/common/assets";
import { UserService } from "@ni/common/utils";
import { UserApi } from "@ni/sdk/apis";
import { RoleRestrictionsObjectLevel, UserFull } from "@ni/sdk/models";

import { useReduxState } from "../../../../../hooks/src";
import { CarbonUserAvatarIcon } from "../../../../../icons/src";

import { AdminNavigationBar } from "./Components/AdminNavigationBar/AdminNavigationBar";
import { Settings } from "./Components/Settings";

import styles from "./styles.module.scss";

const userServiceApi = new UserApi();

interface NavigationBarProperties {
  avatar?: string;
}

export const NavigationBar: FC<NavigationBarProperties> = ({ avatar = "" }) => {
  const [user, setUser] = useReduxState<UserFull>("user");
  const [hasAdministrationGrants, setHasAdministrationGrants] = useState<boolean>(false);
  const [selectedDashboardFI, setSelectedDashboardFI] = useReduxState<string>("selectedDashboardFI", "");
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);

  useEffect(() => {
    userServiceApi
      .getUserMe()
      .then(response => {
        setUser(response.data);
        UserService.updateToken()
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {});
  }, [setUser, setHasAdministrationGrants]);

  useEffect(() => {
    if (!user || !user.userRoles) {
      setHasAdministrationGrants(false);
      return;
    }
    const userRoles = [...(user.userRoles || [])];
    const adminRestrictions = userRoles
      ?.flatMap(role => role.roleRestrictions)
      .filter(
        roleRestriction =>
          // top business data
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.APPLICATION ||
          // configuration data
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.DICTIONARY ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.ELEMENT ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.ELEMENT_TEMPLATE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.DICTIONARY ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.FEATURE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.PAGE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.PHASE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.SMS_TEMPLATE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.TEMPLATE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.VALIDATION ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.VARIABLE ||
          roleRestriction?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.WORKFLOW,
      );
    if (adminRestrictions.length > 0) {
      setHasAdministrationGrants(true);
    }
  }, [user]);

  const logout = async () => {
    try {
      return await UserService.doLogout();
    } catch (error) {
      return error;
    }
  };

  return (
    <div className={styles["navigation-bar"]}>
      <div className={styles["navigation-bar-fixed-wrapper"]}>
        <div className={styles["logo"]}>
          <div className={styles["frame-logo"]}>
            <Link to="/" onClick={() => setSelectedDashboardFI("")}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        {hasAdministrationGrants ? <AdminNavigationBar /> : null}
        <div className={styles["frame-left"]}>
          <p className={styles["financial-institution-title"]} style={{ cursor: "pointer", paddingInline: 8 }}>
            {selectedDashboardFI.length ? selectedDashboardFI : user?.financialInstitution?.name ?? ""}
          </p>

          <div className={styles["line-3-4-1"]} />
          <Settings>
            <Badge>
              <Avatar alt="avatar" icon={avatar || <CarbonUserAvatarIcon />} />
            </Badge>
          </Settings>

          <div className={styles["line-3-4-1"]} />
          {!isPrintMode && (
            <Button type="link" onClick={logout}>
              Logout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
