import { FC, useState } from "react";
import { Space } from "antd";
import TextArea from "antd/es/input/TextArea";

interface EditableCellProps {
  text?: string;
  onSubmit: (value: string) => void;
}

export const EditableCell: FC<EditableCellProps> = ({ text, onSubmit }) => {
  const [value, setValue] = useState(text);
  const [isEditable, setIsEditable] = useState(false);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  return isEditable ? (
    <TextArea
      value={value ?? ""}
      onChange={e => {
        setValue(e.target.value);
      }}
      onKeyDown={e => {
        if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
          if (onSubmit && e.key === "Enter" && value?.trim() !== text?.trim()) {
            onSubmit(value ?? "");
          }
          toggleEdit();
        }
      }}
    />
  ) : (
    <Space className="w-p-100" onClick={toggleEdit}>
      {value?.trim() || "N/A"}
    </Space>
  );
};
