import { FC, useEffect } from "react";
import { Col, Form, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { FormValues } from "@ni/common/types";
import { NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { checkboxFieldsPage11, checkHeader, headerSwitchHandler, switchHeader11 } from "@ni/tenant-portal/core";

import { prepareFieldsToRender, thousandSeparatorFormatter } from "../../../../utils";

import styles from "../../styles.module.scss";

interface PctCardDailyProps {
  currency: string;
  form: FormInstance<FormValues>;
}

export const PctCardMonthly: FC<PctCardDailyProps> = ({ currency, form }) => {
  useEffect(() => {
    Object.keys(checkboxFieldsPage11).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });
    });

    prepareFieldsToRender(checkboxFieldsPage11, (fieldName: string, key?: string) => {
      if (form.getFieldValue(fieldName)) {
        form.setFieldsValue({
          [key || ""]: true,
        });
      }
    });

    checkHeader("card-monthly-all-cash-enable", switchHeader11, form);
    checkHeader("card-monthly-all-retail-enable", switchHeader11, form);
    checkHeader("card-monthly-all-total-enable", switchHeader11, form);
  }, [form]);

  return (
    <>
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="card-monthly-all-cash-enable" className="header-switch">
            <Switch
              onClick={value => headerSwitchHandler("card-monthly-all-cash-enable", switchHeader11, value, form)}
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Cash" tooltipProps={{ title: "Turns off all cash limits." }} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name="nic-c-m-cash-enable" className="col-switch">
                <Switch onClick={() => checkHeader("card-monthly-all-cash-enable", switchHeader11, form)} />
              </Form.Item>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="All"
                  tooltipProps={{
                    title: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <Form.Item name="nic-c-m-cash-max-nr">
                      <NetworkForm.Number
                        className={styles["pct-number-input"]}
                        disabled={!form.getFieldValue("nic-c-m-cash-enable")}
                        formatter={thousandSeparatorFormatter}
                        min={0}
                        stringMode={true}
                        precision={0}
                        step={1}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-cash-max-am">
                      <Form.Item name="nic-c-m-cash-max-am">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-cash-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-cash-max-single">
                      <Form.Item name="nic-c-m-cash-max-single">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-cash-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name="nic-c-m-cash-for-enable" className="col-switch">
                <Switch onClick={() => checkHeader("card-monthly-all-cash-enable", switchHeader11, form)} />
              </Form.Item>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="Foreign"
                  tooltipProps={{
                    title:
                      "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-cash-for-max-nr">
                      <Form.Item name="nic-c-m-cash-for-max-nr">
                        <NetworkForm.Number
                          className={styles["pct-number-input"]}
                          disabled={!form.getFieldValue("nic-c-m-cash-for-enable")}
                          formatter={thousandSeparatorFormatter}
                          min={0}
                          stringMode={true}
                          precision={0}
                          step={1}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-cash-for-max-am">
                      <Form.Item name="nic-c-m-cash-for-max-am">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-cash-for-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-cash-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-cash-for-max-single">
                      <Form.Item name="nic-c-m-cash-for-max-single">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-cash-for-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="card-monthly-all-retail-enable" className="header-switch">
            <Switch
              onClick={value => headerSwitchHandler("card-monthly-all-retail-enable", switchHeader11, value, form)}
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Retail" tooltipProps={{ title: "Turns off all retail transactions." }} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name="nic-c-m-retail-enable" className="col-switch">
                <Switch onClick={() => checkHeader("card-monthly-all-retail-enable", switchHeader11, form)} />
              </Form.Item>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="All"
                  tooltipProps={{
                    title:
                      "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-max-nr">
                      <Form.Item name="nic-c-m-retail-max-nr">
                        <NetworkForm.Number
                          className={styles["pct-number-input"]}
                          disabled={!form.getFieldValue("nic-c-m-retail-enable")}
                          formatter={thousandSeparatorFormatter}
                          min={0}
                          stringMode={true}
                          precision={0}
                          step={1}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-max-am">
                      <Form.Item name="nic-c-m-retail-max-am">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-retail-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-max-single">
                      <Form.Item name="nic-c-m-retail-max-single">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-retail-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name="nic-c-m-retail-for-enable" className="col-switch">
                <Switch onClick={() => checkHeader("card-monthly-all-retail-enable", switchHeader11, form)} />
              </Form.Item>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="Foreign"
                  tooltipProps={{
                    title:
                      "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-for-max-nr">
                      <Form.Item name="nic-c-m-retail-for-max-nr">
                        <NetworkForm.Number
                          className={styles["pct-number-input"]}
                          disabled={!form.getFieldValue("nic-c-m-retail-for-enable")}
                          formatter={thousandSeparatorFormatter}
                          min={0}
                          stringMode={true}
                          precision={0}
                          step={1}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-for-max-am">
                      <Form.Item name="nic-c-m-retail-for-max-am">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-retail-for-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-retail-for-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-retail-for-max-single">
                      <Form.Item name="nic-c-m-retail-for-max-single">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-retail-for-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="card-monthly-all-total-enable" className="header-switch">
            <Switch
              onClick={value => headerSwitchHandler("card-monthly-all-total-enable", switchHeader11, value, form)}
            />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo
              label="Total"
              tooltipProps={{
                title:
                  "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
              }}
            />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max single amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item valuePropName="checked" name="nic-c-m-total-enable" className="col-switch" />
              <div className="col-switch-desc">
                <TooltipInfo
                  label="All"
                  code="nic-c-m-total-enable"
                  tooltipProps={{
                    title:
                      "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-total-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-total-max-nr">
                      <Form.Item name="nic-c-m-total-max-nr">
                        <NetworkForm.Number
                          className={styles["pct-number-input"]}
                          disabled={!form.getFieldValue("nic-c-m-total-enable")}
                          formatter={thousandSeparatorFormatter}
                          min={0}
                          stringMode={true}
                          precision={0}
                          step={1}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-total-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-total-max-am">
                      <Form.Item name="nic-c-m-total-max-am">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-total-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={["nic-c-m-total-enable"]} shouldUpdate={true}>
                {() => {
                  return (
                    <FormItemLabel code="nic-c-m-total-max-single">
                      <Form.Item name="nic-c-m-total-max-single">
                        <NetworkForm.Number
                          addonAfter={currency}
                          stringMode={true}
                          min={0}
                          disabled={!form.getFieldValue("nic-c-m-total-enable")}
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
