import { FC, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import {
  LTY_CASHBACK_AUTO_REDEEM,
  LTY_CASHBACK_INTERVAL,
  LTY_CASHBACK_INTERVAL_RADIO_GROUP,
  LTY_EXT_AUTO_REDEEM,
  LTY_REDEEM_TYPE,
  RADIO_GROUP_LTY_REDEEM_TYPE,
} from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { LoyaltyProgram, Product } from "@ni/sdk/models";

interface EditProgramProps {
  currentProduct: Product;
  loyaltyProgram: LoyaltyProgram;
  editProductLoyaltyProgram: (values: FormValues) => Promise<void>;
}

const stringsKeys = [LTY_REDEEM_TYPE, LTY_CASHBACK_AUTO_REDEEM, LTY_EXT_AUTO_REDEEM, LTY_CASHBACK_INTERVAL];

export const PointRedemptionSetup: FC<EditProgramProps> = ({
  currentProduct,
  loyaltyProgram,
  editProductLoyaltyProgram,
}) => {
  const [form] = Form.useForm<FormValues>();

  useHydrateForm(
    {
      form,
      entityFields: loyaltyProgram?.programValues ?? [],
      keys: {
        strings: stringsKeys,
      },
    },
    [loyaltyProgram],
  );

  const isRedeemTypeCashback = Form.useWatch<string>(LTY_REDEEM_TYPE, form) === "C";
  const isCashbackAutoRedeem = Form.useWatch<boolean>(LTY_CASHBACK_AUTO_REDEEM, form);

  const onFinish = (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && loyaltyProgram?.programValues?.find(item => item.code === key)) {
        values[key] = "";
      }
    });
    void editProductLoyaltyProgram(values);
  };

  const relatedLinks = useMemo(() => {
    return (currentProduct.parameterTables ?? []).map(table => ({
      href: `../pct/${table?.id}/loyalty-settings`,
      label: `${currentProduct?.displayName} - ${table?.displayName} PCT - Loyalty - General Settings`,
    }));
  }, [currentProduct?.displayName, currentProduct.parameterTables]);

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Redemption Setup"
      pageSubtitle="Loyalty points redemption is the process of utilizing accumulated loyalty points to obtain rewards, benefits, or discounts offered by a loyalty program. Below parameters define basic redemption setup for the loyalty program. Redemption rate can be redefined for a particular Pricing control table. Please follow the link at the bottom of the page."
      size="md"
      submitLabel="Save"
      level="product-loyalty"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <Form.Item name={LTY_REDEEM_TYPE} label="Choose Redemption Type:">
        <RadioGroup radioList={RADIO_GROUP_LTY_REDEEM_TYPE} />
      </Form.Item>
      {isRedeemTypeCashback ? (
        <>
          <Space direction="horizontal">
            <Form.Item name={LTY_CASHBACK_AUTO_REDEEM} valuePropName="checked" initialValue={false}>
              <Switch />
            </Form.Item>
            <TooltipInfo label="Enable cashback auto-redemption" tooltipProps={{}} />
          </Space>
          {isCashbackAutoRedeem && (
            <Form.Item
              name={LTY_CASHBACK_INTERVAL}
              label="Choose cashback auto-redemption interval"
              initialValue="Billing"
            >
              <RadioGroup radioList={LTY_CASHBACK_INTERVAL_RADIO_GROUP} initialValue="Billing" />
            </Form.Item>
          )}
        </>
      ) : (
        <Space direction="horizontal">
          <Form.Item name={LTY_EXT_AUTO_REDEEM} valuePropName="checked" initialValue={false}>
            <Switch />
          </Form.Item>
          <TooltipInfo
            label="Enable external auto-redemption"
            tooltipProps={{ title: "On the last day of cycle, after the cumulative accrual" }}
          />
        </Space>
      )}
    </CustomFormWrapper>
  );
};
