import { FC, useMemo } from "react";
import { Form, Select, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  ALPHA2_COUNTRIES_LIST,
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_CHECK_RULES,
  LTY_RULE_CNTR,
  LTY_RULE_CNTR_LIST,
  LTY_RULE_MCC,
  LTY_RULE_MCC_LIST,
  LTY_RULE_MERCH,
  LTY_RULE_MERCH_LIST,
  LTY_RULE_TERM_LIST,
  LTY_RULES_CRITERIA,
  MCC_LIST,
  RADIO_GROUP,
  RADIO_GROUP_ELIGIBLE,
} from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, RadioGroup, Tabs, TooltipInfo } from "@ni/common/ui";
import { LoyaltyProgramTemplate } from "@ni/sdk/models";

import { TAB_TITLES } from "./constants";

type KeyTab = "CNTR" | "TERM" | "MERCH" | "MCC";

interface LoyaltyProgramTemplatesPagesProps {
  loyaltyProgramTemplate: LoyaltyProgramTemplate;
  editLoyaltyProgramTemplate: (
    data: FormValues,
    listKey?: "programValues" | "programPctValues" | "both",
  ) => Promise<void>;
}

const stringsKeys = [LTY_CHECK_RULES, LTY_RULE_MERCH, LTY_RULE_MCC, LTY_RULE_CNTR];
const listsKeys = [LTY_RULES_CRITERIA, LTY_RULE_TERM_LIST, LTY_RULE_MERCH_LIST, LTY_RULE_MCC_LIST, LTY_RULE_CNTR_LIST];

export const TransactionEligibility: FC<LoyaltyProgramTemplatesPagesProps> = ({
  loyaltyProgramTemplate,
  editLoyaltyProgramTemplate,
}) => {
  const [form] = Form.useForm<FormValues>();
  const initialValues = useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
        lists: listsKeys,
      },
    },
    [loyaltyProgramTemplate],
  );

  const isSpecificRetail = Form.useWatch<string>(LTY_CHECK_RULES, form) === "Y";
  const selectedCriteria = Form.useWatch<string[]>(LTY_RULES_CRITERIA, form);

  const tabCriteriaList = useMemo(() => {
    const tabsContent = {
      CNTR: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["CNTR"]}</p>
          <Space direction="horizontal">
            <Switch
              checked={selectedCriteria?.includes("CNTR")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "CNTR"] : selectedCriteria.filter(x => x !== "CNTR"),
                );
              }}
            />
            <TooltipInfo label="Enable country criteria" tooltipProps={{}} />
          </Space>
          {selectedCriteria?.includes("CNTR") && (
            <>
              <Form.Item name={LTY_RULE_CNTR} label="Select countries" initialValue={RADIO_GROUP[0].value}>
                <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
              </Form.Item>
              <Form.Item
                name={LTY_RULE_CNTR_LIST}
                label="Country list"
                rules={[
                  {
                    required: true,
                    validator: (_, value: string[]) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please fill out country list."));
                    },
                  },
                ]}
                className="w-p-50"
              >
                <Select mode="multiple" optionFilterProp="children">
                  {ALPHA2_COUNTRIES_LIST.map(country => (
                    <Select.Option key={country.code} value={country.code}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Space>
      ),
      TERM: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["TERM"]}</p>
          <Space direction="horizontal">
            <Switch
              checked={selectedCriteria?.includes("TERM")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "TERM"] : selectedCriteria.filter(x => x !== "TERM"),
                );
              }}
            />
            <TooltipInfo label="Enable terminal id criteria" tooltipProps={{}} />
          </Space>
          {selectedCriteria?.includes("TERM") && (
            <Form.Item
              name={LTY_RULE_TERM_LIST}
              label="Specify merchant terminal ID list"
              rules={[
                {
                  required: true,
                  validator: (_, value: string[]) => {
                    if (value && value.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Please fill out Id list."));
                  },
                },
              ]}
              className="w-p-50"
            >
              <Select mode="tags" />
            </Form.Item>
          )}
        </Space>
      ),
      MERCH: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["MERCH"]}</p>
          <Space direction="horizontal">
            <Switch
              checked={selectedCriteria?.includes("MERCH")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "MERCH"] : selectedCriteria.filter(x => x !== "MERCH"),
                );
              }}
            />
            <TooltipInfo label="Enable merchant id criteria" tooltipProps={{}} />
          </Space>
          {selectedCriteria?.includes("MERCH") && (
            <>
              <Form.Item name={LTY_RULE_MERCH} label="Select merchants" initialValue={RADIO_GROUP[0].value}>
                <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
              </Form.Item>
              <Form.Item
                name={LTY_RULE_MERCH_LIST}
                label="Specify merchant ID list"
                rules={[
                  {
                    required: true,
                    validator: (_, value: string[]) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please fill out Id list."));
                    },
                  },
                ]}
                className="w-p-50"
              >
                <Select mode="tags" />
              </Form.Item>
            </>
          )}
        </Space>
      ),
      MCC: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["MCC"]}</p>
          <Space direction="horizontal">
            <Switch
              checked={selectedCriteria?.includes("MCC")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "MCC"] : selectedCriteria.filter(x => x !== "MCC"),
                );
              }}
            />
            <TooltipInfo label="Enable merchant category criteria" tooltipProps={{}} />
          </Space>
          {selectedCriteria?.includes("MCC") && (
            <>
              <Form.Item
                name={LTY_RULE_MCC}
                label="Select merchant category code (MCC)"
                initialValue={RADIO_GROUP[0].value}
              >
                <RadioGroup radioList={RADIO_GROUP} initialValue={RADIO_GROUP[0].value} />
              </Form.Item>
              <Form.Item
                name={LTY_RULE_MCC_LIST}
                label="Merchant category code list"
                rules={[
                  {
                    required: true,
                    validator: (_, value: string[]) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please fill out merchant category code (MCC) list."));
                    },
                  },
                ]}
                className="w-p-50"
              >
                <Select mode="multiple" optionFilterProp="children">
                  {MCC_LIST.map(mcc => (
                    <Select.Option key={mcc.key} value={mcc.key}>
                      {mcc.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Space>
      ),
    } as const;

    return loyaltyProgramTemplate?.id && LTY_RULES_CRITERIA
      ? [
          {
            key: "CNTR" as KeyTab,
            label: <div title="Country">Country</div>,
            children: tabsContent["CNTR" as KeyTab],
          },
          {
            key: "MCC" as KeyTab,
            label: <div title="erchant Categor">Merchant Category</div>,
            children: tabsContent["MCC" as KeyTab],
          },
          {
            key: "MERCH" as KeyTab,
            label: <div title="Merchant ID">Merchant ID</div>,
            children: tabsContent["MERCH" as KeyTab],
          },
          {
            key: "TERM" as KeyTab,
            label: <div title="Terminal ID">Terminal ID</div>,
            children: tabsContent["TERM" as KeyTab],
          },
        ]
      : [];
  }, [form, loyaltyProgramTemplate?.id, selectedCriteria]);

  const onFinish = async (values: FormValues) => {
    const stringifiedValues = { ...values };
    [...stringsKeys, ...listsKeys].forEach(key => {
      if (!stringifiedValues[key] && loyaltyProgramTemplate?.programPctValues?.find(item => item.code === key)) {
        stringifiedValues[key] = "";
      } else if (Array.isArray(stringifiedValues[key])) {
        stringifiedValues[key] = (stringifiedValues[key] as string[]).join(",");
      }
    });

    await editLoyaltyProgramTemplate(stringifiedValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Eligibility"
      pageSubtitle="Specify transaction eligibility to participate in loyalty program:"
      size="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={LTY_CHECK_RULES} label="Transaction eligibility mode" initialValue="N">
        <RadioGroup radioList={RADIO_GROUP_ELIGIBLE} initialValue="N" />
      </Form.Item>

      {isSpecificRetail && (
        <div>
          <Form.Item
            name={LTY_RULES_CRITERIA}
            label="Eligibility criteria"
            tooltip={{
              title: "You can specify details for selected criteria on upcoming pages",
              icon: <QuestionCircleFilled />,
            }}
            rules={[{ required: true, message: "Select at least one criteria" }]}
            hidden={true}
          >
            <Checkbox className="m-t-16" checkboxes={CHECKBOX_GROUP_ELIGIBILITY_CRITERIA} />
          </Form.Item>
          <div className="w-p-150">
            <Tabs
              pagesList={tabCriteriaList}
              isCheckEnabled={true}
              form={form}
              initialValues={{
                ...initialValues,
                [LTY_CHECK_RULES]: isSpecificRetail ? "Y" : "N",
              }}
              onSave={onFinish}
              discardAfterChangeTab={false}
              excludedFieldsListFromCheck={[LTY_CHECK_RULES]}
            />
          </div>
        </div>
      )}
    </CustomFormWrapper>
  );
};
