import { FC, useMemo } from "react";
import { Form } from "antd";

import { PAYM_LIM_PERIODS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, SwitchedInputsBox } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import {
  bTotalAmOption,
  bTotalNrOption,
  bTotalSingleOption,
  d365TotalAmOption,
  d365TotalNrOption,
  d365TotalSingleOption,
  dTotalAmOption,
  dTotalNrOption,
  dTotalSingleOption,
  enableATotal,
  enableBTotalOption,
  enableD365TotalOption,
  enableDTotalOption,
  enableETotalOption,
  eTotalAmOption,
  eTotalNrOption,
  eTotalSingleOption,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const AccountLimitsPaymentPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const paymLimPeriodsValue: string = getFormValueFromProductValues(
    wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
    PAYM_LIM_PERIODS,
  )?.toString();

  const { isShowPerDay, isShowPerBilling, isShowPer365Days, isShowForever } = useMemo(
    () => ({
      isShowPerDay: paymLimPeriodsValue?.includes("D"),
      isShowPerBilling: paymLimPeriodsValue?.includes("B"),
      isShowPer365Days: paymLimPeriodsValue?.includes("Y"),
      isShowForever: paymLimPeriodsValue?.includes("F"),
    }),
    [paymLimPeriodsValue],
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [
        enableATotal.code,
        enableDTotalOption.code,
        dTotalNrOption.code,
        dTotalAmOption.code,
        dTotalSingleOption.code,
        enableBTotalOption.code,
        bTotalNrOption.code,
        bTotalAmOption.code,
        bTotalSingleOption.code,
        enableD365TotalOption.code,
        d365TotalNrOption.code,
        d365TotalAmOption.code,
        d365TotalSingleOption.code,
        enableETotalOption.code,
        eTotalNrOption.code,
        eTotalAmOption.code,
        eTotalSingleOption.code,
      ],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const tableOptions = useMemo(() => {
    const options = [];

    if (isShowPerDay) {
      options.push([
        enableDTotalOption,
        dTotalNrOption,
        {
          ...dTotalAmOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
        {
          ...dTotalSingleOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
      ]);
    }

    if (isShowPerBilling) {
      options.push([
        enableBTotalOption,
        bTotalNrOption,
        {
          ...bTotalAmOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
        {
          ...bTotalSingleOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
      ]);
    }

    if (isShowPer365Days) {
      options.push([
        enableD365TotalOption,
        d365TotalNrOption,
        {
          ...d365TotalAmOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
        {
          ...d365TotalSingleOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
      ]);
    }

    if (isShowForever) {
      options.push([
        enableETotalOption,
        eTotalNrOption,
        {
          ...eTotalAmOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
        {
          ...eTotalSingleOption,
          suffix: <div className="currency-suffix">{currencyType}</div>,
          controls: false,
        },
      ]);
    }

    return options;
  }, [isShowPerDay, isShowPerBilling, isShowPer365Days, isShowForever, currencyType]);

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Account Limits - Payment"
      pageSubtitle="Account limits govern payments to cards related to the account and payments to the account itself. Both primary and supplementary card loads contribute towards the account limits"
      size="md"
      formSize="lg"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <SwitchedInputsBox
        form={form}
        formDisabled={formDisabled}
        switchOptions={enableATotal}
        inputsOptionsList={tableOptions}
        shouldClearOnDisabled={true}
      />
    </CustomFormWrapper>
  );
};
