import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useLoyaltyProgramApi, useProductSettings } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const ProductLoyaltyProgramEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: tenantId, productId, programId } = useParams<{ id: string; productId: string; programId: string }>();

  const { currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });

  const loyaltyPrograms = currentProduct.shortLoyaltyPrograms;

  const { loyaltyProgram, editProductLoyaltyProgram } = useLoyaltyProgramApi({
    loyaltyProgramId: parseInt(programId ?? "0", 10),
    isFetchEnabled: true,
  });

  const handleRouteChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/product/${productId}/edit-program/${id}/${getLastRoute(location.pathname)}`);
    },
    [location.pathname, navigate, productId, tenantId],
  );

  return loyaltyProgram?.id && handleRouteChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute({ currentProduct, loyaltyProgram, editProductLoyaltyProgram })}
      deps={[loyaltyProgram?.id, loyaltyPrograms, handleRouteChange]}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect
              value={loyaltyProgram?.id}
              entities={loyaltyPrograms ?? []}
              onChange={handleRouteChange}
            />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
    />
  ) : null;
};
