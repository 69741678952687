import { FC } from "react";

import { TooltipInfo } from "@ni/common/ui";

interface LimitsHeaderProps {
  title: string;
  tooltipText?: string;
  children: React.ReactNode;
}

export const LimitsHeader: FC<LimitsHeaderProps> = ({ title, tooltipText = "", children }) => {
  return (
    <div className="limits-block-header">
      {children}
      <div className="col-switch-desc">
        <TooltipInfo label={title} tooltipProps={{ title: tooltipText }} />
      </div>
    </div>
  );
};
