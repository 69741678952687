import { Form, notification, Space, Switch, Typography } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  DECLINE_FEE_ENABLED,
  NIC_DECL_TRANS_FEE_MAX_NR,
  NIC_DECL_TRANS_FEE_VAL,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

const stringsKeys = [DECLINE_FEE_ENABLED, NIC_DECL_TRANS_FEE_VAL, NIC_DECL_TRANS_FEE_MAX_NR];

export const DeclineFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const declineFeeEnabled = Form.useWatch<boolean>(DECLINE_FEE_ENABLED, form);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: stringsKeys,
    },
  });

  const onFinish = async (values: FormValues) => {
    try {
      if (!declineFeeEnabled) {
        [...stringsKeys].forEach(key => {
          if (!values[key]?.toString() && pct?.pctProductValues?.find(item => item.fieldCode === key)) {
            values[key] = "";
          }
        });
      }
      await onSavePct(values);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Decline Fee"
      pageSubtitle="Decline transaction fee is charged in case of any decline transactions as per response code list. Response
  code list can be defined in the system to consider the declined transactions."
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <Space direction="horizontal">
        <Form.Item valuePropName="checked" name={DECLINE_FEE_ENABLED}>
          <Switch />
        </Form.Item>
        <Typography.Text strong={true}>
          <TooltipInfo
            label="Enable decline fee for your product"
            tooltipProps={{
              title: "You can configure this list at the Fee settings (visit Fees Tenant Configuration page)",
            }}
          />
        </Typography.Text>
      </Space>

      {declineFeeEnabled && (
        <>
          <Form.Item dependencies={[DECLINE_FEE_ENABLED]} shouldUpdate={true}>
            {() => {
              return (
                <Form.Item
                  name={NIC_DECL_TRANS_FEE_VAL}
                  label="Decline transaction fee amount"
                  rules={[
                    {
                      validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                      message: "Minimum fee amount can not be 0",
                    },
                    { required: declineFeeEnabled, message: "Decline transaction fee amount is required" },
                  ]}
                >
                  <NetworkForm.Number
                    precision={2}
                    addonAfter={productCurrency}
                    disabled={!form.getFieldValue(DECLINE_FEE_ENABLED)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item dependencies={[DECLINE_FEE_ENABLED]} shouldUpdate={true}>
            {() => {
              return (
                <Form.Item
                  name={NIC_DECL_TRANS_FEE_MAX_NR}
                  label="Free of charge decline transactions per cycle"
                  tooltip={{
                    title: "Specify the number of courtesy decline transactions for your customer per cycle",
                    icon: <QuestionCircleFilled />,
                  }}
                >
                  <NetworkForm.Number min={0} precision={0} disabled={!form.getFieldValue(DECLINE_FEE_ENABLED)} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </>
      )}
    </CustomFormWrapper>
  );
};
