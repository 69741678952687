import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import {
  balanceComponentsOptions,
  delinquencyAgingDayOptions,
  delinquencyAgingTriggerOptions,
  fullPaymentDateOptions,
  latePaymentDateOptions,
  latePaymentTriggerOptions,
  paymentDueDateOptions,
  smsDueReminderFlag,
  smsDueReminderOptions,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const PaymentDuePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [
        paymentDueDateOptions.code,
        delinquencyAgingDayOptions.code,
        smsDueReminderFlag.code,
        smsDueReminderOptions.code,
        fullPaymentDateOptions.code,
        balanceComponentsOptions.code,
        latePaymentDateOptions.code,
        latePaymentTriggerOptions.code,
        delinquencyAgingTriggerOptions.code,
      ],
    },
    allowParse: true,
  });

  const hasSmsDueReminder = Form.useWatch<string>(smsDueReminderFlag.code, form)?.toString() === "true";
  const dueDateDays = +(Form.useWatch<string>(paymentDueDateOptions.code, form) as unknown as number);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Payment and Delinquency Settings"
      pageSubtitle="Payment due dates, delinquency and late payment events, allows for optimizing and managing the payment process. Additionally setting SMS due reminders enhances customer experience and improves collection efficiency. Offset days can be customized for each pricing control table after wizard."
      size="md"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={paymentDueDateOptions.code}
        label={<FormItemLabel label={paymentDueDateOptions.name} code={paymentDueDateOptions.code} />}
        initialValue={paymentDueDateOptions.default}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: paymentDueDateOptions.tooltip,
        }}
        rules={[
          { required: true, message: `Payment due date is required!` },
          {
            type: "number",
            max: paymentDueDateOptions.max as number,
            message: "Due date cannot exceed cycle length",
          },
        ]}
      >
        <NetworkForm.Number min={paymentDueDateOptions.min} precision={paymentDueDateOptions.decimalsNumber} />
      </Form.Item>
      <Space direction="vertical" size={25}>
        <Form.Item
          name={balanceComponentsOptions.code}
          label={<FormItemLabel label={balanceComponentsOptions.name} code={balanceComponentsOptions.code} />}
          initialValue={balanceComponentsOptions.default}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: balanceComponentsOptions.tooltip,
          }}
        >
          <RadioGroup
            radioList={balanceComponentsOptions.options}
            initialValue={balanceComponentsOptions.default as string}
          />
        </Form.Item>

        <Form.Item
          name={fullPaymentDateOptions.code}
          label={fullPaymentDateOptions.label}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: fullPaymentDateOptions.tooltip,
          }}
          rules={[
            {
              type: "number",
              max: 30 - dueDateDays,
              message: "Late payment date cannot exceed remaining cycle days after setting a due date",
            },
          ]}
        >
          <NetworkForm.Number {...fullPaymentDateOptions.props} />
        </Form.Item>
      </Space>
      <Space direction="vertical" size={25}>
        <Space direction="horizontal">
          <Form.Item name={smsDueReminderFlag.code} valuePropName="checked" initialValue={smsDueReminderFlag.default}>
            <Switch />
          </Form.Item>
          <TooltipInfo
            label={smsDueReminderFlag.name}
            code={smsDueReminderFlag.code}
            tooltipProps={{
              title: smsDueReminderFlag.tooltip,
            }}
          />
        </Space>
        {hasSmsDueReminder && (
          <Form.Item
            name={smsDueReminderOptions.code}
            label={<FormItemLabel label={smsDueReminderOptions.name} code={smsDueReminderOptions.code} />}
            initialValue={smsDueReminderOptions.default}
            rules={[{ required: true, message: `SMS due reminder is required!` }]}
            tooltip={{
              icon: <QuestionCircleFilled />,
              title: balanceComponentsOptions.tooltip,
            }}
          >
            <NetworkForm.Number min={smsDueReminderOptions.min} precision={smsDueReminderOptions.decimalsNumber} />
          </Form.Item>
        )}
      </Space>

      <Space direction="vertical" size={25}>
        <Form.Item
          name={latePaymentTriggerOptions.code}
          label={<FormItemLabel label={latePaymentTriggerOptions.name} code={latePaymentTriggerOptions.code} />}
          initialValue={latePaymentTriggerOptions.default}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: latePaymentTriggerOptions.tooltip,
          }}
        >
          <RadioGroup
            radioList={latePaymentTriggerOptions.options}
            initialValue={latePaymentTriggerOptions.default as string}
          />
        </Form.Item>
        <Form.Item
          name={latePaymentDateOptions.code}
          label={<FormItemLabel label={latePaymentDateOptions.name} code={latePaymentDateOptions.code} />}
          initialValue={latePaymentDateOptions.default}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: latePaymentDateOptions.tooltip,
          }}
          rules={[
            {
              type: "number",
              max: (latePaymentDateOptions.max as number) - dueDateDays,
              message: "Late payment date cannot exceed remaining cycle days after setting a due date",
            },
          ]}
        >
          <NetworkForm.Number min={latePaymentDateOptions.min} precision={latePaymentDateOptions.decimalsNumber} />
        </Form.Item>
      </Space>

      <Space direction="vertical" size={25}>
        <Form.Item
          name={delinquencyAgingTriggerOptions.code}
          label={
            <FormItemLabel label={delinquencyAgingTriggerOptions.name} code={delinquencyAgingTriggerOptions.code} />
          }
          initialValue={delinquencyAgingTriggerOptions.default}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: delinquencyAgingTriggerOptions.tooltip,
          }}
        >
          <RadioGroup
            radioList={delinquencyAgingTriggerOptions.options}
            initialValue={delinquencyAgingTriggerOptions.default as string}
          />
        </Form.Item>
        <Form.Item
          name={delinquencyAgingDayOptions.code}
          label={<FormItemLabel label={delinquencyAgingDayOptions.name} code={delinquencyAgingDayOptions.code} />}
          initialValue={delinquencyAgingDayOptions.default}
          tooltip={{
            icon: <QuestionCircleFilled />,
            title: delinquencyAgingDayOptions.tooltip,
          }}
          rules={[
            { required: true, message: "Delinquency aging day is required!" },
            {
              type: "number",
              max: (delinquencyAgingDayOptions.max as number) - (dueDateDays ?? 0),
              message: "Delinquency date cannot exceed remaining cycle days after setting a due date",
            },
          ]}
        >
          <NetworkForm.Number min={delinquencyAgingDayOptions.min} precision={paymentDueDateOptions.decimalsNumber} />
        </Form.Item>
      </Space>
    </CustomFormWrapper>
  );
};
