import { FC, useState } from "react";
import { Button, Checkbox, Form, Modal, notification } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxProps } from "antd/lib";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useReduxState, useUATDisableForm } from "@ni/common/hooks";
import { FormValues, Value } from "@ni/common/types";
import {
  CollapsedLinks,
  LoyaltyProgramListItem,
  PageItemLayoutGeneral,
  SettingsPageLayout,
  TooltipInfo,
} from "@ni/common/ui";
import { getErrorInstance, sortLoyaltyPrograms } from "@ni/common/utils";
import { ProductApi } from "@ni/sdk/apis";
import { BaseLoyaltyProgramDashboard, LoyaltyProgram, LoyaltyProgramType, Product } from "@ni/sdk/models";

import { LoyaltyTable } from "../../../components";

import styles from "../styles.module.scss";

const productApi = new ProductApi();

export const LoyaltyPrograms: FC = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading", false);
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");

  const [modalOpen, setModalOpen] = useState(false);

  const [loyaltyPrograms, setLoyaltyPrograms] = useReduxState<LoyaltyProgram[]>("loyaltyPrograms", []);
  const [loyaltyProgramTemplates] = useReduxState<BaseLoyaltyProgramDashboard[]>("LoyaltyProgramTemplates", []);

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const isDisabled = useUATDisableForm();

  const checkAll = loyaltyProgramTemplates.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < loyaltyProgramTemplates.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e: CheckboxChangeEvent) => {
    const loyaltyProgramTemplateIds = (
      e.target.checked ? loyaltyProgramTemplates.map(template => template.id!) : []
    ) as Value;

    setCheckedList(loyaltyProgramTemplateIds! as CheckboxValueType[]);
    form.setFieldsValue({ loyaltyProgramTemplateIds });
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setCheckedList(prev => {
      const index = prev.findIndex(item => item === e.target.value);

      if (index !== -1) return [...prev.slice(0, index), ...prev.slice(index + 1)];

      return [...prev, e.target.value] as CheckboxValueType[];
    });
  };

  const renderPageSubtitle = () => {
    const prgrmHasContent = loyaltyPrograms.length === 0;
    const tmpltsHasContent = loyaltyProgramTemplates.length === 0;

    return prgrmHasContent ? (
      `The product is currently not associated with any loyalty program. You can add loyalty to your product by choosing from the Loyalty Program Templates. ${
        tmpltsHasContent
          ? "However, it seems that you haven't created any template yet. Please visit the Loyalty Program Templates section in the tenant settings."
          : ""
      }`
    ) : (
      <TooltipInfo
        largeLabel="The product can be associated with multiple loyalty programs, including up to 5 transaction based and up to 5 cumulative programs. Below is the list of applicable loyalty programs, arranged in descending order of priority."
        tooltipProps={{
          title:
            "The loyalty program priority determines how points are accrued when a transaction is eligible for multiple programs. Points will be accrued based on the conditions of the program with the highest priority. To change program priority, simply drag and drop the program to a higher position in the list.",
        }}
      />
    );
  };

  const onFormFinish = async (values: FormValues) => {
    setIsLoading(true);

    try {
      const { data } = await productApi.applyLoyaltyProgramTemplates(
        { loyaltyProgramTemplateIds: values["loyaltyProgramTemplateIds"] as Set<number> },
        parseInt(productId ?? "", 10),
      );
      setLoyaltyPrograms(sortLoyaltyPrograms(data));
      setModalOpen(false);
      setCheckedList([]);

      form.setFieldsValue({ loyaltyProgramTemplateIds: [] });
    } catch (err) {
      const errorInstance = getErrorInstance(err);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response.status ?? 400} <br />
            {errorInstance?.response.data.errorMessage ?? SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderLinks = () => {
    const links = [
      {
        href: `/tenant/${tenantId}/loyalty-program-templates`,
        label: "Loyalty Program Templates",
      },
    ];

    if (loyaltyPrograms.length > 0) {
      links.push({
        href: `/tenant/${tenantId}/product/${productId}/loyalty-settings`,
        label: `${currentProduct.displayName} - Loyalty - General Settings`,
      });
    }

    return links;
  };

  return (
    <SettingsPageLayout pageTitle="Loyalty Programs" pageSubtitle={!isLoading && renderPageSubtitle()}>
      <PageItemLayoutGeneral>
        {loyaltyPrograms.length > 0 && <LoyaltyTable productId={parseInt(productId ?? "0", 10)} />}

        <Modal
          title="You can add up to 5 loyalty programs of each type (transaction based / cumulative) to your product"
          open={modalOpen}
          width="568px"
          onCancel={() => setModalOpen(false)}
          footer={[
            <Button key="back" disabled={isLoading} onClick={() => setModalOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={form.submit}
              disabled={checkedList.length === 0}
            >
              Confirm
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" onFinish={onFormFinish} disabled={isLoading}>
            <Form.Item name="selectAll" className="m-b-8">
              <Checkbox
                indeterminate={indeterminate}
                checked={checkAll}
                onChange={onCheckAllChange}
                className="text-primary"
              >
                Select all
              </Checkbox>
            </Form.Item>

            <Form.Item name="loyaltyProgramTemplateIds" className="w-p-100">
              <Checkbox.Group className={styles["lty-modal-group"]}>
                {loyaltyProgramTemplates.map((template: BaseLoyaltyProgramDashboard) => (
                  <LoyaltyProgramListItem
                    key={`${template.id}-${template.name}`}
                    name={template.name ?? "Draft"}
                    value={template.id ?? "0"}
                    sections={[
                      {
                        title: "Accrual Type",
                        value:
                          template.accrualType === LoyaltyProgramType.NORMAL
                            ? "Transaction based"
                            : template.accrualType === LoyaltyProgramType.CUMULATIVE
                              ? "Cumulative"
                              : "-",
                      },
                      { title: "Point accrual", value: template.accrualDisplayDetails ?? "-" },
                      {
                        title: "Redemption type",
                        value:
                          template.redemptionType === "C"
                            ? "Cashback"
                            : template.redemptionType === "E"
                              ? "External Voucher"
                              : "-",
                      },
                    ]}
                    onChange={onChange}
                  />
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
        {!isPrintMode && (
          <Button
            size="large"
            type="primary"
            htmlType="button"
            disabled={isDisabled || loyaltyProgramTemplates.length === 0}
            loading={isLoading}
            onClick={() => setModalOpen(true)}
          >
            Add loyalty program
          </Button>
        )}
      </PageItemLayoutGeneral>
      {!isPrintMode && <CollapsedLinks list={renderLinks()} />}
    </SettingsPageLayout>
  );
};
