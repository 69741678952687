import { FC } from "react";
import { Form } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { LTY_ACCR_B_RATE, LTY_ACCR_CAP_CTD } from "@ni/common/constants";
import { useHydrateForm, useNumberOfTotalPointesCapDecimals, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const PointAccrualCalculationBillingCycle: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);
  const { processWizardRequest } = useLoyaltyWizardApi();

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      strings: [LTY_ACCR_B_RATE, LTY_ACCR_CAP_CTD],
    },
  });

  const decimalScale = useNumberOfTotalPointesCapDecimals(wizardResponse?.tenant);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: { ...values } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={false}
      pageTitle="Point Calculation - Billing Cycle Accrual"
      pageSubtitle="Points are earned as a percentage of eligible transactions, capped at the defined upper threshold."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item
        name={LTY_ACCR_B_RATE}
        required={true}
        rules={[{ required: true }]}
        label="Points accrual percentage rate"
        tooltip={{
          title:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle. ",
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number min={0} precision={2} addonAfter="%" stringMode={true} />
      </Form.Item>
      <Form.Item
        name={LTY_ACCR_CAP_CTD}
        label="Total points cap per cycle"
        tooltip={{
          title:
            "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number min={0} precision={decimalScale} stringMode={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
