import { FC, useMemo } from "react";
import { Alert, Form } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, RadioGroup } from "@ni/common/ui";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../../hooks";

import { tab1 } from "./constants";

export const ProductPremiumSettings: FC = () => {
  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();
  const [form] = Form.useForm<FormValues>();
  const {
    currentInsuranceProgram: currentInsurance,
    editInsurance,
    formValuesConverter,
    currency,
  } = useInsuranceProgramApi();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [
        tab1.capOption.code,
        tab1.fixedOption.code,
        tab1.percentageOption.code,
        tab1.periodOption.code,
        tab1.periodTypeOption.code,
      ],
    },
    allowParse: true,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: "Insurance Program Templates",
        href: `/tenant/${tenantId}/insurance-program-templates`,
      },
      ...(currentProduct.parameterTables?.flatMap(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/insurance-edit/${insuranceId}/insurance-premium-settings?tab=setting`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - ${currentInsurance?.name} - Premium Settings`,
      })) || []),
    ],
    [currentInsurance, currentProduct, tenantId, productId, insuranceId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Premium Settings"
      pageSubtitle="Insurance is billed to the customer at the end of the billing cycle with the amount calculated based on
            multiple parameters. It can be either a percentage of the statement balance or a fixed amount, charged as an
            insurance premium. You can choose to reverse the premium for initial number of months or cycles, while
            reversing the insurance premium; system will take care of deducting that out of payment due amount and
            retail plan or full fee cash plan."
      size="md"
      formSize="md"
      gap={24}
      submitLabel="Save"
      submitHandler={onFinish}
      level="product"
      linkTitle="Product Settings"
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={tab1.periodOption.code}
        label={tab1.periodOption.name}
        tooltip={{
          title: tab1.periodOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          min={tab1.periodOption.min}
          max={tab1.periodOption.max}
          precision={tab1.periodOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={tab1.periodTypeOption.code}
        label={tab1.periodTypeOption.name}
        initialValue={tab1.periodTypeOption.default}
      >
        <RadioGroup radioList={tab1.periodTypeOption.options} initialValue={tab1.periodTypeOption.default as string} />
      </Form.Item>

      <Alert
        message="Below preferences will be applied to all contracts under this product, you can override below parameter(s) value for a specific pricing control table in related screens."
        type="info"
        showIcon={true}
      />

      <Form.Item
        name={tab1.fixedOption.code}
        label={tab1.fixedOption.name}
        tooltip={{
          title: tab1.fixedOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          addonAfter={currency}
          min={tab1.fixedOption.min}
          max={tab1.fixedOption.max}
          precision={tab1.fixedOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={tab1.percentageOption.code}
        label={tab1.percentageOption.name}
        tooltip={{
          title: tab1.percentageOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
        initialValue={tab1.percentageOption.default}
      >
        <NetworkForm.Number
          addonAfter="%"
          min={tab1.percentageOption.min}
          max={tab1.percentageOption.max}
          precision={tab1.percentageOption.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={tab1.capOption.code}
        label={tab1.capOption.name}
        tooltip={{
          title: tab1.capOption.tooltip,
          icon: <QuestionCircleFilled />,
        }}
      >
        <NetworkForm.Number
          addonAfter={currency}
          min={tab1.capOption.min}
          max={tab1.capOption.max}
          precision={tab1.capOption.decimalsNumber}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
