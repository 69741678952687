import { FC } from "react";
import { Col, Form, FormInstance, Row } from "antd";

import { NetworkForm } from "@ni/common/ui";

import { thousandSeparatorFormatter } from "../../../utils";

import styles from "./styles.module.scss";

interface InputsNames {
  maxNumber: string;
  maxAmount: string;
  maxSingleAmount: string;
}

interface LimitsValuesRowProps {
  limitName: string;
  limitKey: string;
  currency: string;
  form: FormInstance;
  inputsNames: InputsNames;
  children: React.ReactNode;
}

export const LimitsValuesRow: FC<LimitsValuesRowProps> = ({
  limitName,
  limitKey,
  currency,
  form,
  inputsNames,
  children,
}) => (
  <Row>
    <Col span={6}>
      {children}
      <div className="col-switch-desc">{limitName}</div>
    </Col>
    <Col span={6} className={styles["limits-input"]}>
      <Form.Item dependencies={[limitKey]} shouldUpdate={true}>
        {() => (
          <Form.Item name={inputsNames.maxNumber}>
            <NetworkForm.Number
              min={0}
              step={1}
              precision={0}
              formatter={thousandSeparatorFormatter}
              disabled={!form.getFieldValue(limitKey)}
            />
          </Form.Item>
        )}
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item dependencies={[limitKey]} shouldUpdate={true}>
        {() => (
          <Form.Item name={inputsNames.maxAmount}>
            <NetworkForm.Number
              min={0}
              precision={0}
              addonAfter={currency}
              disabled={!form.getFieldValue(limitKey)}
              stringMode={true}
            />
          </Form.Item>
        )}
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item dependencies={[limitKey]} shouldUpdate={true}>
        {() => (
          <Form.Item name={inputsNames.maxSingleAmount}>
            <NetworkForm.Number
              stringMode={true}
              min={0}
              precision={0}
              addonAfter={currency}
              disabled={!form.getFieldValue(limitKey)}
            />
          </Form.Item>
        )}
      </Form.Item>
    </Col>
  </Row>
);
