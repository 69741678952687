import { FC, useMemo } from "react";
import { Form, Select, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { MCC_LIST, NIC_CCY, NIC_MADA_USE, PctValueParams } from "@ni/common/constants";
import { useFormButtonDisabled, useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { filterByDisplayValue, getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionRestrictionsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const isMada = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_MADA_USE) as string) === "true",
    [wizardResponse.product?.productValues],
  );

  const productCurrency = useMemo(
    () => getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY) as string,
    [wizardResponse.product?.productValues],
  );

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form, initialState: false });

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [
        PctValueParams.moto,
        PctValueParams.ecomm,
        PctValueParams.contactless,
        PctValueParams.fallback,
        PctValueParams.country,
        PctValueParams.atm,
        PctValueParams.all,
      ],
      lists: [PctValueParams.eCommerceList],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        ...(values[PctValueParams.eCommerceList] && {
          [PctValueParams.eCommerceList]: (values[PctValueParams.eCommerceList] as string[]).join(","),
        }),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Transaction Restrictions"
      pageSubtitle={
        <>
          Transaction Restrictions regulate transactions based on various criteria, including channels, geographic
          areas, merchants, and transaction attributes, and can be applied at different organizational levels.
          Transactions can be restricted by specific criteria, such as Mail Order/Telephone Order (MOTO) transactions,
          allowing precise control.
          <br />
          <br />
          Merchant Category Codes (MCCs), four-digit codes assigned to businesses by financial institutions, help
          classify the type of goods or services provided. The system can restrict authorizations based on MCCs, which
          must be configured accordingly. When a transaction is restricted, a clear response code &quot;Transaction not
          permitted to cardholder&quot; informs cardholders of the decline reason
        </>
      }
      size="md"
      formSize="full"
      gap={40}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Form.Item label="Please select the transaction types to be disabled">
        <Space direction="vertical" size={0}>
          <Space direction="horizontal">
            <Form.Item name={PctValueParams.moto} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="MOTO (Mail Order/ Telephone Order) transactions"
              code={PctValueParams.moto}
              tooltipProps={{
                title:
                  "These are a type of card not present (CNP) payment. The customer provides merchant with his payment card details over the phone, via email or in a letter. Instead of swiping a card, the card data is entered manually. This can be done via a conventional card terminal as well as a virtual terminal.",
              }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.ecomm} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="E-commerce transactions"
              code={PctValueParams.ecomm}
              tooltipProps={{
                title:
                  "E-commerce (electronic commerce) is the buying and selling of goods and services, or the transmitting of funds or data, over an electronic network, primarily the internet.",
              }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.contactless} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="Contactless transactions"
              code={PctValueParams.contactless}
              tooltipProps={{
                title:
                  "Contactless payments are transactions made by tapping either a contactless chip card or payment-enabled mobile or wearable device over a contactless-enabled payment terminal. Cards, phones, watches and other devices use the same contactless technology.",
              }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.fallback} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="Chip fallback transactions"
              code={PctValueParams.fallback}
              tooltipProps={{
                title:
                  "Chip fallback refers to a transaction where a customer uses an EMV (chip) card in a chip-capable terminal, but the transaction doesn’t go through as a chip transaction. Instead it “falls back” on magnetic strip technology.",
              }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.country} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="Foreign country transactions"
              code={PctValueParams.country}
              tooltipProps={{
                title: "Transactions that occurs in the country different from card issuance country.",
              }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.atm} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="Cash ATM transactions"
              code={PctValueParams.atm}
              tooltipProps={{ title: "Cash withdrawal transactions at Automated Teller Machines." }}
            />
          </Space>

          <Space direction="horizontal">
            <Form.Item name={PctValueParams.all} valuePropName="checked">
              <Switch />
            </Form.Item>
            <TooltipInfo
              label="All debit transactions"
              code={PctValueParams.all}
              tooltipProps={{ title: "Any kind of transactions that leads to debiting user account." }}
            />
          </Space>
        </Space>
      </Form.Item>

      <Form.Item
        name={PctValueParams.eCommerceList}
        label={
          <FormItemLabel
            label="Please enter the Merchant Category Code (MCC) to exclude"
            code={PctValueParams.eCommerceList}
          />
        }
        tooltip={{
          title:
            "System provides the feasibility to restrict certain transactions which are done on specific MCC's (Merchant category code). One or more MCC's can be defined in the system to restrict / block the user authorizations. The restriction of MCCs is allowed can be overridden at the user account level to allow transactions in special cases.",
          icon: <QuestionCircleFilled />,
        }}
      >
        <Select mode="multiple" optionFilterProp="children" filterOption={filterByDisplayValue}>
          {MCC_LIST.map(mcc => (
            <Select.Option key={mcc.key} value={mcc.key}>
              <FormItemLabel label={mcc.value} code={mcc.key} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {isMada && (
        <Form.Item
          name={PctValueParams.mada}
          label={
            <FormItemLabel
              label="MADA (Regulatory) contactless cumulative amount limit (per card)"
              code={PctValueParams.mada}
            />
          }
          className="wizard-inner-block wizard-input-pct"
          initialValue={300}
        >
          <NetworkForm.Number className="w-p-100" addonAfter={productCurrency} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
