import { FormValues, RoutesTreeItem } from "@ni/common/types";
import { LoyaltyProgram, Product } from "@ni/sdk/models";

import { ProductEdit } from "../../../pages";

interface Props {
  currentProduct: Product;
  loyaltyProgram: LoyaltyProgram;
  editProductLoyaltyProgram: (data: FormValues) => Promise<void>;
}

export const pagesRoute = ({ currentProduct, loyaltyProgram, editProductLoyaltyProgram }: Props) =>
  [
    {
      name: "Point Accrual Setup",
      route: "point-accrual-setup",
      element: (
        <ProductEdit.PointAccrualSetup
          currentProduct={currentProduct}
          loyaltyProgram={loyaltyProgram}
          editProductLoyaltyProgram={editProductLoyaltyProgram}
        />
      ),
    },
    {
      name: "Point Redemption Setup",
      route: "point-redemption-setup",
      element: (
        <ProductEdit.PointRedemptionSetup
          currentProduct={currentProduct}
          loyaltyProgram={loyaltyProgram}
          editProductLoyaltyProgram={editProductLoyaltyProgram}
        />
      ),
    },
  ] as RoutesTreeItem[];
