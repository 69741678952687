import { FC } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useUATDisableForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, SwitchedInputsBox } from "@ni/common/ui";

import { usePlanApi } from "../../../../hooks";

import {
  enableProcessingFeeOption,
  feeFixedOption,
  feePercentageOption,
  maxFeeOption,
  minFeeOption,
} from "./constants";

const stringsKeys = [
  enableProcessingFeeOption.code,
  feeFixedOption.code,
  feePercentageOption.code,
  maxFeeOption.code,
  minFeeOption.code,
];

export const IPPProcessingFee: FC = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, pctId, planId } = useParams<{ id: string; pctId: string; planId: string }>();

  const isDisabled = useUATDisableForm();

  const feePercentage = Form.useWatch<string>(feePercentageOption.code, form)?.toString();
  const minFee = +(Form.useWatch<string>(minFeeOption.code, form) as unknown as number);
  const maxFee = +(Form.useWatch<string>(maxFeeOption.code, form) as unknown as number);

  const { currentPlan, editPlan, formValuesConverter } = usePlanApi(parseInt(planId ?? "0", 10));

  useHydrateForm(
    {
      form,
      entityFields:
        currentPlan.planPctValues && currentPlan?.planPctValues?.length > 0
          ? currentPlan.planPctValues
          : currentPlan.planValues && currentPlan?.planValues?.length > 0
            ? currentPlan.planValues
            : [],
      keys: {
        strings: stringsKeys,
      },
      allowParse: true,
    },
    [currentPlan],
  );

  const onFinish = async (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && currentPlan?.planPctValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });
    await editPlan({ planPctValues: formValuesConverter(values, parseInt(pctId!, 10)) });
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/edit-ipp/${currentPlan.id}/profit?tab=processing`,
      label: `Installment Payment Plans - ${currentPlan.name} - Processing Fee`,
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="IPP Processing Fee"
      pageSubtitle="These parameters specify the fee that needs to be charged to the cardholder as a processing fee in case the transaction amount or balance is booked for this plan."
      level="product"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="PCT Installment Plans"
      submitHandler={onFinish}
      additionalRoute={`/pct/${pctId}/installment-payment-plans`}
      relatedLinks={relatedLinks}
    >
      <SwitchedInputsBox
        form={form}
        switchOptions={enableProcessingFeeOption}
        formDisabled={isDisabled}
        inputsOptionsList={[
          [
            { ...feeFixedOption },
            feePercentageOption,
            {
              ...minFeeOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
            },
            {
              ...maxFeeOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
            },
          ],
        ]}
      />
    </CustomFormWrapper>
  );
};
