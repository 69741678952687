import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Product, ProductState } from "@ni/sdk/models";

import { useReduxState } from "../store";

const useUATDisableForm = () => {
  const [currentProduct] = useReduxState<Product>("currentProduct", {});
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const isProductOrPctPage = pathname.includes("/product/") || pathname.includes("/pct/");
    const isUAT =
      currentProduct.productState === ProductState.UAT ||
      currentProduct.productState === ProductState.UAT_PENDING ||
      currentProduct.productState === ProductState.REJECTED;

    setIsDisabled(!!(isProductOrPctPage && isUAT));
  }, [currentProduct?.id, currentProduct.productState, pathname]);

  return isDisabled;
};

export { useUATDisableForm };
