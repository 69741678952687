import { Form, Space, Switch, Typography } from "antd";
import { checkEveryPctValue } from "libs/product-settings/src/lib/utils";
import { useParams } from "react-router-dom";

import {
  INACT_FEE_BILLING_EN,
  INACT_FEE_ONCE_EN,
  NIC_INACT_FEE_BILLING_VAL,
  NIC_INACT_FEE_ONCE_VAL,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";

import { usePct } from "../../../../hooks";

import styles from "../../styles.module.scss";

export const InactivityFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const isFeeOnceEnabled = Form.useWatch<boolean>(INACT_FEE_ONCE_EN, form);
  const isFeeBillingEnabled = Form.useWatch<boolean>(INACT_FEE_BILLING_EN, form);

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [INACT_FEE_ONCE_EN, NIC_INACT_FEE_ONCE_VAL, INACT_FEE_BILLING_EN, NIC_INACT_FEE_BILLING_VAL],
    },
  });

  const onFinish = async (values: FormValues) => {
    const productValues: FormValues = {};

    const feeOnceDisabled = checkEveryPctValue({
      pctId,
      parameterTables: currentProduct.parameterTables,
      fieldCode: INACT_FEE_ONCE_EN,
      fieldValue: "false",
      newFieldValue: values[INACT_FEE_ONCE_EN],
    });

    const feeBillingDisabled = checkEveryPctValue({
      pctId,
      parameterTables: currentProduct.parameterTables,
      fieldCode: INACT_FEE_BILLING_EN,
      fieldValue: "false",
      newFieldValue: values[INACT_FEE_BILLING_EN],
    });

    if (feeOnceDisabled && feeBillingDisabled) {
      productValues["nic-inact-period-unit"] = "";
      productValues["nic-inact-period"] = "";
    }

    await onUpdateProduct(productValues, undefined, {
      id: Number(pctId),
      values: { [NIC_INACT_FEE_ONCE_VAL]: "", [NIC_INACT_FEE_BILLING_VAL]: "", ...values },
    });
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id || ""}/fees-settings`,
      label: `Fee Settings - ${currentProduct?.displayName || ""}`,
    },
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Inactivity Fee"
      pageSubtitle={
        <TooltipInfo
          largeLabel="Inactivity fee is the fee charged when there is no activity on the account for specific period (3 months by
        default). The inactivity state is defined by absence of financial activities on card excluding fees and
        charges transactions."
          tooltipProps={{
            title:
              "You can change inactivity period window at product settings (visit Fees Product Configuration page).",
          }}
        />
      }
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Space direction="vertical" size={16}>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={INACT_FEE_ONCE_EN}>
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>Enable one-time inactivity fee</Typography.Text>
          </Space>

          {isFeeOnceEnabled && (
            <Form.Item
              name={NIC_INACT_FEE_ONCE_VAL}
              label="Amount charged once (on exceeding inactivity period)"
              rules={[
                {
                  validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                  message: "Minimum fee amount can not be 0",
                },
                { required: isFeeOnceEnabled, message: "Amount charged once is required" },
              ]}
            >
              <NetworkForm.Number precision={2} addonAfter={productCurrency} disabled={!isFeeOnceEnabled} />
            </Form.Item>
          )}
        </Space>
      </PageItemLayoutGeneral>

      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Space direction="vertical" size={16}>
          <Space direction="horizontal">
            <Form.Item valuePropName="checked" name={INACT_FEE_BILLING_EN}>
              <Switch />
            </Form.Item>
            <Typography.Text strong={true}>
              <TooltipInfo
                label="Enable regular inactivity fee"
                tooltipProps={{
                  title: "This charge will be collected on each statement day for as long as inactive",
                }}
              />
            </Typography.Text>
          </Space>

          {isFeeBillingEnabled && (
            <Form.Item
              name={NIC_INACT_FEE_BILLING_VAL}
              label="Amount charged on the regular base (on statement day)"
              rules={[
                {
                  validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                  message: "Minimum fee amount can not be 0",
                },
                { required: isFeeBillingEnabled, message: "Amount charged on the regular base is required" },
              ]}
            >
              <NetworkForm.Number precision={2} addonAfter={productCurrency} disabled={!isFeeBillingEnabled} />
            </Form.Item>
          )}
        </Space>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
