import { Form } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { NIC_CASH_LIMIT_ACC_RATE } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import { accountCashLimitOptions, cardCashLimitOptions, shadowLimitOptions } from "./CreditLimitConstants";

export const CreditLimitPage = () => {
  const { pctId, productId, id } = useParams<{ id: string; productId: string; pctId: string }>();

  const [form] = Form.useForm<FormValues>();

  const { currentProduct, pct, onSavePct, getValueFromPct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: [
      ...(currentProduct.productValues ?? []),
      ...(pct.pctProductValues ?? []),
      {
        fieldCode: cardCashLimitOptions.code,
        value: (
          getValueFromPct(cardCashLimitOptions.code) ||
          getFormValueFromProductValues(currentProduct.productValues, NIC_CASH_LIMIT_ACC_RATE)
        ).toString(),
      },
    ],
    keys: {
      strings: [accountCashLimitOptions.code, shadowLimitOptions.code, cardCashLimitOptions.code],
    },
    allowParse: true,
  });

  const onFinish = onSavePct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Credit Limit"
      pageSubtitle="Credit limit refers to the total line of credit that can be used by an account to make purchases. Fees and interests billed to the account also make up for the total limit available to be used (Open-To-Buy). Credit limit can be set at account or card level. Card level Credit Limit cannot exceed the limit of account level."
      size="md"
      formSize="md"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={[
        {
          href: `/tenant/${id}/product/${productId}/credit-limit`,
          label: `${currentProduct.displayName} - Credit Limit`,
        },
      ]}
    >
      <Form.Item name={accountCashLimitOptions.code} label={accountCashLimitOptions.name}>
        <NetworkForm.Number
          addonAfter="%"
          min={accountCashLimitOptions.min}
          max={accountCashLimitOptions.max}
          precision={accountCashLimitOptions.decimalsNumber}
        />
      </Form.Item>

      <Form.Item name={cardCashLimitOptions.code} label={cardCashLimitOptions.name}>
        <NetworkForm.Number
          addonAfter="%"
          min={cardCashLimitOptions.min}
          max={cardCashLimitOptions.max}
          precision={cardCashLimitOptions.decimalsNumber}
        />
      </Form.Item>

      <Form.Item
        name={shadowLimitOptions.code}
        label={shadowLimitOptions.name}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: shadowLimitOptions.tooltip,
        }}
      >
        <NetworkForm.Number
          addonAfter="%"
          min={shadowLimitOptions.min}
          max={shadowLimitOptions.max}
          precision={shadowLimitOptions.decimalsNumber}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
