import { useMemo } from "react";
import { Button, Space } from "antd";
import { useLocation } from "react-router-dom";

import { LockOutlined } from "@ant-design/icons";
import { useProductSettings, useReduxState } from "@ni/common/hooks";
import { Product, ProductState } from "@ni/sdk/models";

import styles from "../styles.module.scss";

const testPathnameRegex = /^\/tenant\/\d*\/product\/[\d]{1,}\//;

export const AfterWizardEditButton = () => {
  const { pathname } = useLocation();
  const [currentProduct] = useReduxState<Product>("currentProduct", {});
  const { onUpdateProduct } = useProductSettings({ productId: currentProduct?.id, isFetchEnabled: false });

  const shouldShow = useMemo(
    () =>
      testPathnameRegex.test(pathname) &&
      !!currentProduct?.id &&
      currentProduct?.productState === ProductState.REJECTED,
    [currentProduct?.id, currentProduct?.productState, pathname],
  );

  const handleClick = () => {
    if (currentProduct?.id) {
      void onUpdateProduct({ productState: ProductState.DRAFT }, currentProduct?.productValues);
    }
  };

  return shouldShow ? (
    <Space className={styles["edit-button-container"]}>
      <Button size="large" type="primary" htmlType="submit" icon={<LockOutlined />} onClick={handleClick}>
        Unlock Product
      </Button>
    </Space>
  ) : null;
};
