import { FC, useEffect, useState } from "react";
import { Input } from "antd";

import { displayReadableSmsVariables } from "@ni/common/utils";
import { Variable } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const { TextArea } = Input;

interface SmsVariablePreviewProps {
  text: string;
  variables: Variable[];
}

export const SmsVariablePreview: FC<SmsVariablePreviewProps> = ({ text = "", variables = [] }) => {
  const [content, setContent] = useState(text);

  useEffect(() => {
    setContent(displayReadableSmsVariables(text, variables));
  }, [text, variables]);

  return (
    <TextArea
      className={styles["sms-variable-preview"]}
      rows={4}
      bordered={true}
      showCount={{
        formatter: () => `${content?.length || 0}/150`,
      }}
      value={content}
      disabled={false}
      onChange={() => {}}
    />
  );
};
