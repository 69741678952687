import { useCallback, useEffect, useState } from "react";
import { Button, Tabs as AntdTabs } from "antd";

import { ChangesChecker, useParamsList, useTabs } from "@ni/common/hooks";
import { FormValues, Tab } from "@ni/common/types";

import { UnsavedChangesModal } from "../Modal";

interface TabsProps<K extends string> extends ChangesChecker {
  pagesList: Tab<K>[];
  defaultActiveKey?: K;
  disableTabKey?: boolean;
  onSave?: (values: FormValues, lastActiveRoute?: string) => void;
}

export const Tabs = <K extends string>({
  pagesList = [],
  isCheckEnabled = false,
  form,
  excludedFieldsListFromCheck = [],
  initialValues,
  discardAfterChangeTab = true,
  disableTabKey,
  onSave,
  defaultActiveKey = pagesList[0].key,
}: TabsProps<K>) => {
  const {
    params: { tab },
    setParams,
  } = useParamsList(["tab"]);
  const [lastActiveTab, setLastActiveTab] = useState<K>();
  const [tabs, activeTab, setActiveTab, changesChecker] = useTabs<K>(pagesList, {
    isCheckEnabled,
    form,
    excludedFieldsListFromCheck,
    initialValues,
    discardAfterChangeTab,
  });

  const handleTabChange = useCallback(
    (newTab: string) => {
      setLastActiveTab(tab as K);
      if (!disableTabKey) setParams({ tab: newTab });
      setActiveTab(newTab as K);
    },
    [disableTabKey, setActiveTab, setParams, tab],
  );

  const {
    nextPage = {
      name: "",
      key: "",
    },
    isOpen,
    disabled,
    onDiscardChanges = (key: K) => {
      handleTabChange(key);
      return key;
    },
    onCloseModal,
  } = changesChecker || {};

  const onStay = () => {
    if (onCloseModal) {
      if (!disableTabKey) setParams({ tab: lastActiveTab ?? defaultActiveKey });
      onCloseModal();
    }
  };

  const onFinish = () => {
    if (form && onSave) {
      onDiscardChanges(nextPage.key as K, false);
      onSave(form.getFieldsValue(), lastActiveTab);
    }
  };

  useEffect(() => {
    if (!tab && defaultActiveKey && pagesList.some(page => page.key === defaultActiveKey))
      handleTabChange(defaultActiveKey);

    if (tab && pagesList.some(page => page.key === tab)) {
      setActiveTab(tab as K);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentTab = tabs.find(y => y.key === tab);
    const doesTabNotExist = currentTab?.dependency === false;
    if (doesTabNotExist) handleTabChange(defaultActiveKey);
  }, [defaultActiveKey, handleTabChange, tab, tabs]);

  return (
    <div className="ni-tabs">
      <AntdTabs
        activeKey={activeTab.key}
        onChange={handleTabChange}
        items={tabs.filter(tab => tab?.dependency ?? true)}
      />
      {isCheckEnabled && (
        <UnsavedChangesModal
          isOpen={!!isOpen}
          footer={[
            <Button key="discard" onClick={() => onDiscardChanges(nextPage.key as K, true)}>
              Discard
            </Button>,
            <Button key="stay" onClick={() => onStay()}>
              Stay
            </Button>,
            <Button key="Save" danger={true} type="primary" onClick={onFinish} disabled={disabled}>
              Save
            </Button>,
          ]}
        />
      )}
    </div>
  );
};
