import { useCallback, useEffect, useState } from "react";
import { notification, TablePaginationConfig } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { getErrorInstance } from "@ni/common/utils";
import { ConfigurationApi } from "@ni/sdk/apis";
import { Dictionaries, SortedFilteredPageRequest } from "@ni/sdk/models";

import { WITHOUT_UPDATES_MESSAGE } from "../components/DictionariesHeader/DictionariesHeader.constants";

const configurationApi = new ConfigurationApi();

interface UseDictionariesProps {
  searchValue: string;
}

export const useDictionaries = ({ searchValue }: UseDictionariesProps) => {
  const [dictionaries, setDictionaries] = useReduxState<Dictionaries[]>("dictionaries");
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });

  const getDictionariesList = useCallback(
    (pageRequest: SortedFilteredPageRequest): void => {
      if (pageRequest && Object.keys(pageRequest)) {
        setIsLoading(true);
        configurationApi
          .getDictionariesList(pageRequest)
          .then(response => {
            const dictionaries = response.data.content?.sort((a, b) => (a.code > b.code ? 1 : -1)) ?? [];
            setDictionaries(dictionaries);
            setTablePagination(prevTablePagination => ({
              ...prevTablePagination,
              total: response.data.totalElements,
            }));
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    },
    [setDictionaries, setIsLoading],
  );

  const deleteDictionariesByCode = async (code: string) => {
    setIsLoading(true);
    try {
      await configurationApi.deleteDictionariesByCode(code);
      const tempDictionaries = dictionaries.filter(elem => elem.code !== code);
      setDictionaries(tempDictionaries);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const syncWithWay4 = async (dictionaryCodes: string[]) => {
    setIsLoading(true);

    try {
      const response = await configurationApi.syncWithWay4({ dictionaryCodes });
      getDictionariesList({
        pageLimits: {
          size: tablePagination.pageSize,
          number: (tablePagination.current ?? 1) - 1,
        },
      });

      const { dictionaries } = response.data;
      const { status } = response;

      let notificationMessage = WITHOUT_UPDATES_MESSAGE;
      if (dictionaries && dictionaries.length !== 0) {
        notificationMessage = `Synchronized dictionaries: ${dictionaries.map(it => it.code).join(", ")}`;

        notification.success({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {status}
              <br />
              {notificationMessage}
            </div>
          ),
        });
        return;
      }

      notification.warning({
        placement: "topRight",
        duration: 5,
        message: (
          <div>
            {status}
            <br />
            {notificationMessage}
          </div>
        ),
      });
    } catch (error) {
      const errorInstance = getErrorInstance(error);

      notification.error({
        placement: "topRight",
        duration: 5,
        message: (
          <div>
            {errorInstance.response.status}
            <br />
            {WITHOUT_UPDATES_MESSAGE}
          </div>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(
    () =>
      getDictionariesList({
        pageLimits: {
          size: tablePagination.pageSize,
          number: (tablePagination.current ?? 1) - 1,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDictionariesList, tablePagination.pageSize, tablePagination.current],
  );

  const onUpdateTablePagination = (pagination: TablePaginationConfig) => {
    setTablePagination(prevPagination => ({
      ...prevPagination,
      ...pagination,
    }));
  };

  return {
    tablePagination: [tablePagination, onUpdateTablePagination] as [
      TablePaginationConfig,
      (pagination: TablePaginationConfig) => void,
    ],
    filteredDictionaries: searchValue
      ? dictionaries.filter(elem => elem.code.toLowerCase().includes(searchValue.toLowerCase()))
      : dictionaries,
    deleteDictionariesByCode,
    syncWithWay4,
  };
};
