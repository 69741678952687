import { useMemo } from "react";
import { Form, Space, Switch } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { NIC_CCY } from "@ni/common/constants";
import { useHydrateForm, useRemainingCycleDayAfterDueDate } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import {
  tawarruqPaymentDue,
  tawarruqProfitAmount,
  tawarruqProfitBase,
  tawarruqProfitPercentage,
  tawarruqSalesFee,
  tawarruqSalesFeeAmount,
  tawarruqSalesFeePercentage,
  tawarruqSalesFeeType,
} from "./constants";

const stringsKeys = [
  tawarruqPaymentDue.code,
  tawarruqProfitAmount.code,
  tawarruqProfitBase.code,
  tawarruqProfitPercentage.code,
  tawarruqSalesFee.code,
  tawarruqSalesFeeAmount.code,
  tawarruqSalesFeePercentage.code,
  tawarruqSalesFeeType.code,
];

export const TawarruqProfitCalculationPage = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const tawarruqBase = Form.useWatch(tawarruqProfitBase.code, form);
  const tawarruqSalesFeeEnabled = Form.useWatch(tawarruqSalesFee.code, form);
  const tawarruqSalesFeeBase = Form.useWatch(tawarruqSalesFeeType.code, form);

  const dueDateDays = useRemainingCycleDayAfterDueDate(pct.pctProductValues);
  const { productCurrency, relatedLinks } = useMemo(
    () => ({
      productCurrency: getFormValueFromProductValues(currentProduct.productValues, NIC_CCY),

      relatedLinks: [
        {
          href: `/tenant/${tenantId}/credit-products-configuration`,
          label: "Interest Calculation - Global",
        },
        {
          href: `/tenant/${tenantId}/product/${productId}/interest-grace-options`,
          label: `${currentProduct.displayName} - Interest Grace Options`,
        },
      ],
    }),
    [currentProduct.displayName, currentProduct.productValues, productId, tenantId],
  );

  useHydrateForm(
    {
      form,
      entityFields: [...(currentProduct?.productValues ?? []), ...(pct?.pctProductValues ?? [])],
      keys: {
        strings: stringsKeys,
      },
    },
    [pct.pctProductValues],
  );

  const onFinish = (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && pct.pctProductValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    void onSavePct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Tawarruq Profit Calculation"
      pageSubtitle="Tawarruq profit is calculated on the full payment date based on the remaining Tawarruq balance - includes all billed amounts, fees, charges & profits- and is posted to accountholder on full payment date, since profit is calculated on the full payment date, it is charged on that date and cannot be held until billing date."
      size="md"
      formSize="md"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <Form.Item
        name={tawarruqProfitBase.code}
        label={tawarruqProfitBase.label}
        initialValue={tawarruqProfitBase.default}
      >
        <RadioGroup radioList={tawarruqProfitBase.options} gap={8} />
      </Form.Item>

      {tawarruqBase !== "Amount" && (
        <Form.Item
          name={tawarruqProfitPercentage.code}
          label={tawarruqProfitPercentage.label}
          rules={[{ required: true, message: "Tawarruq profit percentage is required" }]}
        >
          <NetworkForm.Number {...tawarruqProfitPercentage.props} />
        </Form.Item>
      )}

      {tawarruqBase !== "Percentage" && (
        <Form.Item
          name={tawarruqProfitAmount.code}
          label={tawarruqProfitAmount.label}
          rules={[{ required: true, message: "Tawarruq profit amount is required" }]}
        >
          <NetworkForm.Number {...tawarruqProfitAmount.props} addonAfter={productCurrency} />
        </Form.Item>
      )}

      <Space direction="horizontal" size={8}>
        <Form.Item valuePropName="checked" name={tawarruqSalesFee.code}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={tawarruqSalesFee.label} tooltipProps={{ title: tawarruqSalesFee.tooltip }} />
      </Space>

      {tawarruqSalesFeeEnabled && (
        <Space direction="vertical" size={40}>
          <Form.Item
            name={tawarruqSalesFeeType.code}
            label={tawarruqSalesFeeType.label}
            initialValue={tawarruqSalesFeeType.default}
          >
            <RadioGroup radioList={tawarruqSalesFeeType.options} gap={8} />
          </Form.Item>

          {tawarruqSalesFeeBase === "Percentage" && (
            <Form.Item
              name={tawarruqSalesFeePercentage.code}
              label={tawarruqSalesFeePercentage.label}
              rules={[{ required: true, message: "Tawarruq sales fee percentage is required" }]}
            >
              <NetworkForm.Number {...tawarruqSalesFeePercentage.props} />
            </Form.Item>
          )}

          {tawarruqSalesFeeBase === "Amount" && (
            <Form.Item
              name={tawarruqSalesFeeAmount.code}
              label={tawarruqSalesFeeAmount.label}
              rules={[{ required: true, message: "Tawarruq sales fee amount is required" }]}
            >
              <NetworkForm.Number {...tawarruqSalesFeeAmount.props} addonAfter={productCurrency} />
            </Form.Item>
          )}
        </Space>
      )}

      <Form.Item
        name={tawarruqPaymentDue.code}
        label={tawarruqPaymentDue.label}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: tawarruqPaymentDue.tooltip,
        }}
        rules={[
          {
            type: "number",
            max: 30 - dueDateDays,
            message: "Late payment date cannot exceed remaining cycle days after setting a due date",
          },
        ]}
      >
        <NetworkForm.Number {...tawarruqPaymentDue.props} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
